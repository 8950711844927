import {
    getLocation as _getLocation,
    connectRouter,
} from 'connected-react-router/immutable';
import { History } from 'history';

import { ReducersMapObject } from 'redux';
import withoutRouter from './withoutRouter';

export * from './withoutRouter';

export default function createReducer(
    history: History,
    asyncReducers: ReducersMapObject = {},
) {
    return withoutRouter({
        router: connectRouter(history),
        ...asyncReducers,
    });
}

/* ******************************* */
/*           router                */
/* ******************************* */

export function getLocation(state: any) {
    return _getLocation({ router: state.get('router') });
}
