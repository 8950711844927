import { withLayout, LayoutWithRightDrawer } from 'core/layouts';
import { ROLES } from '@bondvet/roles';
import { createLoadableComponent } from '../util/loading';

function loadDashboard() {
    return import(
        /* webpackPrefetch: true, webpackChunkName: "dashboard.Dashboard" */ './Dashboard'
    );
}

const Dashboard = createLoadableComponent('Dashboard', loadDashboard);

export default (prefix: string) => [
    {
        exact: false,
        path: `${prefix}`,
        component: withLayout(Dashboard, LayoutWithRightDrawer as any),
        roles: [ROLES.clinicData],
    },
];
