import jwtDecode from 'jwt-decode';
import localforage from 'localforage';
import * as mutations from './mutations';
import { GRAPHQL_CLIENT_NAMES } from '../../common/lib/constants';

let CSRF_TOKEN: string | undefined;
let ACCESS_TOKEN: string | undefined;
let REFRESH_TOKEN: string | undefined;

export type TokenListener = (token: string) => void;

export type TokenSet = {
    csrfToken?: string;
    accessToken?: string;
    refreshToken?: string;
};

export type TokenPair = {
    accessToken?: string;
    refreshToken?: string;
};

export interface JwtPayload {
    exp: number;
    sub: string;
}

const TOKEN_LISTENERS: Array<TokenListener> = [];

const store = localforage.createInstance({ name: '@bondvet/apollo-client' });

/**
 * checks if the given JWT token is expired or about to expire
 * within the next minute
 * @param {String} token JWT token
 * @returns Boolean
 */
export function isStillValid(token: string): boolean {
    const { exp } = jwtDecode<JwtPayload>(token);

    if (exp === undefined) {
        // token doesn't expire
        return true;
    }

    // "exp" field is the UNIX timestamp in seconds
    const limit = Math.floor(Date.now() / 1000) + 60;

    return limit <= exp;
}

/**
 * retrieves a new access token for the given refresh token
 * @param {String} refreshToken
 * @returns {Promise<String>} the new access token
 */
async function extendToken(refreshToken: string) {
    const { client } = await import('@bondvet/web-app-lib');
    const {
        data: {
            extendToken: { accessToken },
        },
    } = await client.mutate({
        mutation: mutations.extendToken,
        variables: { __noAuth: true, refreshToken },
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.auth,
        },
    });

    return accessToken;
}

/**
 * notifies all registered listeners about the new access token
 * @param {String} accessToken
 */
function notifyAccessTokenListeners(accessToken: string) {
    TOKEN_LISTENERS.slice(0).forEach((listener) => {
        try {
            listener(accessToken);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('error notifying access token listener', error);
        }
    });
}

/**
 *
 * @param {Function} listener
 * @returns {Function} unregister function
 */
export function onNewAccessToken(listener: TokenListener) {
    TOKEN_LISTENERS.push(listener);
    return function unlisten() {
        const idx = TOKEN_LISTENERS.indexOf(listener);

        if (idx !== -1) {
            TOKEN_LISTENERS.splice(idx, 1);
        }
    };
}

/**
 * @typedef {Object} TokenSet
 *
 * @property {String} csrfToken
 * @property {String} accessToken
 * @property {String} refreshToken
 */

/**
 * @returns {Promise<TokenSet>}
 */
export async function readStoredToken(): Promise<TokenSet> {
    const [csrfToken, accessToken, refreshToken] = (await Promise.all(
        ['csrfToken', 'accessToken', 'refreshToken'].map((key) =>
            store.getItem(key),
        ),
    )) as [string | undefined, string | undefined, string | undefined];

    if (csrfToken) {
        CSRF_TOKEN = csrfToken;
    }

    if (accessToken) {
        ACCESS_TOKEN = accessToken;
    }

    if (refreshToken) {
        REFRESH_TOKEN = refreshToken;
    }

    return { csrfToken, accessToken, refreshToken };
}

/**
 * @typedef {Object} TokenPair
 *
 * @property {String} accessToken
 * @property {refreshToken} accessToken
 */

/**
 *
 * @param {TokenPair}
 * @returns {Promise<void>}
 */
export async function storeToken({
    accessToken,
    refreshToken,
}: TokenPair): Promise<void> {
    const promises = [];

    if (accessToken) {
        if (accessToken !== ACCESS_TOKEN) {
            notifyAccessTokenListeners(accessToken);
        }
        ACCESS_TOKEN = accessToken;
        promises.push(store.setItem('accessToken', accessToken));
    }

    if (refreshToken) {
        REFRESH_TOKEN = refreshToken;
        promises.push(store.setItem('refreshToken', refreshToken));
    }

    await Promise.all(promises);
}

/**
 * stores the given CSRF token
 *
 * @param {String} csrfToken
 * @returns {Promise<void>}
 */
export async function storeCsrfToken(csrfToken: string): Promise<void> {
    CSRF_TOKEN = csrfToken;
    await store.setItem('csrfToken', csrfToken);
}

/**
 * clears all stored token
 */
export async function clearToken(): Promise<void> {
    CSRF_TOKEN = undefined;
    ACCESS_TOKEN = undefined;
    REFRESH_TOKEN = undefined;

    await Promise.all(
        ['csrfToken', 'accessToken', 'refreshToken'].map((key) =>
            store.removeItem(key),
        ),
    );
}

/**
 * @returns {Promise<String>}
 */
export async function getCsrfToken(): Promise<string | undefined> {
    if (CSRF_TOKEN) {
        return CSRF_TOKEN;
    }

    const { csrfToken } = await readStoredToken();

    return csrfToken;
}

/**
 * @returns {Promise<String>}
 */
export async function getAccessToken(): Promise<string | undefined> {
    if (ACCESS_TOKEN) {
        return ACCESS_TOKEN;
    }

    const { accessToken } = await readStoredToken();

    return accessToken;
}

/**
 * @returns {<Promise<String>}
 */
export async function getRefreshToken(): Promise<string | undefined> {
    if (REFRESH_TOKEN) {
        return REFRESH_TOKEN;
    }

    const { refreshToken } = await readStoredToken();

    return refreshToken;
}

/**
 * @returns {Promise<String>}
 */
export async function getValidAccessToken(): Promise<string> {
    const accessToken = await getAccessToken();

    if (accessToken && isStillValid(accessToken)) {
        return accessToken;
    }

    // no access token or it has expired already
    const refreshToken = await getRefreshToken();

    if (refreshToken) {
        const extendedAccessToken = await extendToken(refreshToken);

        await storeToken({ accessToken: extendedAccessToken });

        return extendedAccessToken;
    }

    throw new Error('error.noRefreshToken');
}
