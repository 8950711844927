import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';

function loadAuthorize() {
    return import('./Authorize');
}

function loadTranslations() {
    return import('../../i18n');
}

export const Authorize = Loadable({
    componentName: 'Authorize',
    loader: loadAuthorize,
    translations: loadTranslations,
    translationsScope: 'oauth',
    loading: Loading,
});
