import { createLoadableComponent } from '_embedded_packages/web-app-account/util/loading';
import { withLayout } from 'core/layouts';

function loadAccount() {
    return import(
        /* webpackPrefetch: true, webpackChunkName: "account.Account" */ './Account'
    );
}

const Account = createLoadableComponent('Account', loadAccount);

export default (prefix: string) => [
    { exact: true, path: `${prefix}`, component: withLayout(Account) },
];
