import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { withLayout } from '../../layouts';

export const AccessDenied = withLayout(
    Loadable({
        componentName: 'AccessDenied',
        loader: () => import('./AccessDenied'),
        redux: () => import('../../redux'),
        translations: () => import('../../i18n'),
        reducerName: 'auth',
        loading: Loading,
    }),
);
