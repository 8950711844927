import * as React from 'react';
import { withStyles } from 'tss-react/mui';
import { PureComponent } from 'react';
import { getStyles, Styles } from './styles';
import { Navigation } from '../../components/Navigation';
import { Header } from '../../components/Header';
import { NavigationTab } from '../../lib';

interface MainProps {
    homePath?: string;
    homeLabel?: string;
    hasRightDrawer?: boolean;
    rightDrawerOpen?: boolean;
    toggleRightDrawer?: () => void;
    SubNavigation?: React.ComponentType<any>;
}

interface MainState {
    mobileOpen: boolean;
}

export function createMainLayout(
    tabs: NavigationTab[],
    rightTabs: NavigationTab[],
    variant: 'light' | 'dark' = 'light',
) {
    class MainComponent extends PureComponent<
        React.PropsWithChildren<MainProps> & Styles,
        MainState
    > {
        state = {
            mobileOpen: false,
        };

        onToggleDrawer = () => {
            this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
        };

        render() {
            const {
                classes,
                children = null,
                homePath = '/',
                homeLabel = 'home',
                hasRightDrawer = false,
                rightDrawerOpen = false,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                toggleRightDrawer = () => {},
                SubNavigation = null,
                ...props
            } = this.props;
            const { mobileOpen } = this.state;

            return (
                <section className={classes?.root}>
                    <Header
                        onToggleDrawer={this.onToggleDrawer}
                        mobileOpen={mobileOpen}
                        hasRightDrawer={hasRightDrawer}
                        rightDrawerOpen={rightDrawerOpen}
                        toggleRightDrawer={toggleRightDrawer}
                        {...props}
                        rightTabs={
                            <Navigation
                                mobileOpen={mobileOpen}
                                onToggleDrawer={this.onToggleDrawer}
                                tabs={rightTabs}
                                variant={variant}
                                homeLabel={homeLabel}
                                homePath={homePath}
                                {...props}
                            />
                        }
                    >
                        <Navigation
                            mobileOpen={mobileOpen}
                            onToggleDrawer={this.onToggleDrawer}
                            tabs={tabs}
                            rightTabs={rightTabs}
                            variant={variant}
                            homeLabel={homeLabel}
                            homePath={homePath}
                            {...props}
                        />
                    </Header>
                    {SubNavigation && (
                        <SubNavigation
                            mobileOpen={mobileOpen}
                            onToggleDrawer={this.onToggleDrawer}
                        />
                    )}
                    <main className={classes?.contentWrapper}>
                        <div className={classes?.toolbar} />
                        {SubNavigation && (
                            <div className={classes?.secondToolbar} />
                        )}
                        <div className={classes?.content}>{children}</div>
                    </main>
                </section>
            );
        }
    }

    return withStyles(MainComponent, getStyles, { name: 'layout--main' });
}
