import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import classnames from 'classnames';
import { WithTranslate } from '@bondvet/web-app-i18n/util';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Styles } from './styles';
import { NavigationTab } from '../../lib';
import logo from '../../assets/logoWithText.inline.svg';
import { Tab } from './Tab';

interface MobileProps extends Styles, WithTranslate {
    mobileOpen: boolean;
    onToggleDrawer?: () => void;
    homePath?: string;
    homeLabel?: string;
    rightTabs?: NavigationTab[];
    tabs: NavigationTab[];
}

function MobileComponent({
    classes,
    onToggleDrawer,
    mobileOpen,
    tabs,
    rightTabs,
    translate,
    history,
}: MobileProps & RouteComponentProps) {
    return (
        <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={onToggleDrawer}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
        >
            <div>
                <div
                    className={classnames(
                        classes.toolbar,
                        classes.mobileToolbar,
                    )}
                >
                    <img
                        className={classes.logo}
                        alt="logo"
                        src={logo}
                        onClick={() => history.push('/')}
                    />
                </div>
                <List
                    role="navigation"
                    component="nav"
                    className={classes.mobileList}
                >
                    {/* TODO activeClassName={classes.selectedMenuEntry} exact to={homePath} */}
                    {tabs.concat(rightTabs || []).map((tab) => (
                        <Tab
                            key={typeof tab === 'string' ? tab : tab.path}
                            tab={tab}
                            icon={
                                typeof tab === 'string' ? undefined : tab.icon
                            }
                            translate={translate}
                            classes={classes}
                            mobile
                        >
                            {typeof tab === 'string' ? undefined : tab.children}
                        </Tab>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

export const Mobile =
    compose<React.FC<MobileProps>>(withRouter)(MobileComponent);
