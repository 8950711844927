import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { selectors } from '@bondvet/web-app-lib/redux';
import { withStyles } from 'tss-react/mui';
import { withTranslate, WithTranslate } from '@bondvet/web-app-i18n/util';
import { Map } from 'immutable';
import { clearNotifications } from '../../redux/actions';
import { getStyles, Styles } from './styles';

interface NotificationsProps {
    clear?: () => void;
    message?: string;
    data?: Map<string, any>;
    autoHideDuration?: number;
}

interface NotificationsState {
    open: boolean;
}

export class NotificationsComponent extends React.PureComponent<
    NotificationsProps & WithTranslate & Styles,
    NotificationsState
> {
    state = { open: this.props.message !== '' };

    UNSAFE_componentWillReceiveProps({ message }: NotificationsProps) {
        if (message !== this.props.message) {
            this.setState({ open: message !== '' });
        }
    }

    onClose = () => {
        if (this.props.clear) {
            this.props.clear();
        }
    };

    render() {
        const {
            message = '',
            translate,
            data,
            autoHideDuration = 3000,
            classes,
        } = this.props;
        const { open } = this.state;

        return (
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration}
                onClose={this.onClose}
                message={open && translate(message, data?.toJS())}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                action={
                    open && (
                        <IconButton
                            aria-label={translate('app.close') as string}
                            color="inherit"
                            className={classes.closeIcon}
                            onClick={this.onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                }
            />
        );
    }
}

function mapStateToProps(state: any) {
    return {
        message: selectors.getNotficationMessage(state),
        data: selectors.getNotificationData(state),
    };
}

function mapDispatchToProps() {
    return (dispatch: Dispatch) =>
        bindActionCreators({ clear: clearNotifications }, dispatch);
}

export const Notifications = compose<React.FC>(
    withTranslate,
    connect(mapStateToProps, mapDispatchToProps),
)(
    withStyles(NotificationsComponent, getStyles, {
        name: 'web-app-notifications__notifications',
    }),
);
