import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'common/hooks/useAnalytics';
import useLocationsWithVetspireId from 'common/hooks/useLocationsWithVetspireId';
import useRegions from './useRegions';

const INTERVAL_DURATION = parseInt(
    process.env.REACT_APP_TRACKING_INTERVAL_DURATION || '40000',
    10,
);

const PAGE_OPEN_EVENT = 'open_page';

export default function usePageTracking() {
    const { pathname } = useLocation();
    const interval = React.useRef<ReturnType<typeof setInterval> | null>(null);
    const { locations, isLoading } = useLocationsWithVetspireId();
    const { regions, isLoading: regionsLoading } = useRegions();
    const { trackEvent, trackEventDuration } = useAnalytics();

    React.useEffect(() => {
        if (locations && !isLoading && regions && !regionsLoading) {
            const location = locations.find(
                (l) => pathname.indexOf(`/${l._id}/`) !== -1,
            );

            const region = regions.find(
                (r) => pathname.indexOf(`/${r._id}/`) !== -1,
            );

            let newPathname = pathname;
            if (location) {
                newPathname = pathname.replaceAll(
                    `/${location._id}/`,
                    '/:locationId/',
                );
            }
            if (region) {
                newPathname = pathname.replaceAll(
                    `/${region._id}/`,
                    '/:regionId/',
                );
            }

            trackEvent(newPathname, PAGE_OPEN_EVENT, {
                locationId: location?._id,
                regionId: region?._id,
            }).then((eventId) => {
                if (eventId !== undefined) {
                    interval.current = setInterval(() => {
                        trackEventDuration(eventId).then();
                    }, INTERVAL_DURATION);
                }
            });
        }

        return () => {
            if (interval.current !== null) {
                clearInterval(interval.current);
            }
        };
    }, [
        isLoading,
        locations,
        regions,
        regionsLoading,
        pathname,
        trackEvent,
        trackEventDuration,
    ]);
}
