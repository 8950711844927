import { Action } from 'redux';

import { Map, fromJS } from 'immutable';
import * as routines from './routines';

export const initialState = fromJS({
    loggingIn: false,
    loading: false,
    user: null,
    error: null,
    isLoadingMe: false,
    me: null,
    booted: false,
});

export interface AuthAction extends Action<string> {
    payload?: any;
}

export default function reducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    { type, ...action }: AuthAction,
) {
    switch (type) {
        case routines.login.TRIGGER:
            return state.set('error', null);
        case routines.login.REQUEST:
            return state.set('loggingIn', true);
        case routines.login.FULFILL:
            return state.set('loggingIn', false);
        case routines.login.FAILURE:
            return state.set('error', action.payload);
        case routines.login.SUCCESS:
            return state.set('user', fromJS(action.payload));

        case routines.readStoredUser.REQUEST:
            return state.set('loading', true);
        case routines.readStoredUser.FULFILL:
            return state.set('loading', false).set('booted', true);

        case routines.logout.TRIGGER:
            return state.set('user', null);

        case routines.loadMe.REQUEST:
            return state.set('isLoadingMe', true);
        case routines.loadMe.FULFILL:
            return state.set('isLoadingMe', false);
        case routines.loadMe.SUCCESS: {
            const me = fromJS(action.payload);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const oldMe = getMe(state);

            if (oldMe && oldMe.equals(me)) {
                return state;
            }

            return state.set('me', me);
        }
        default:
            return state;
    }
}

export function isLoggingIn(state: Map<string, any>) {
    return state.get('loggingIn');
}

export function isLoadingUser(state: Map<string, any>) {
    return state.get('loading');
}

export function isLoggedIn(state: Map<string, any>) {
    return state.get('user') !== null;
}

export function getLoginError(state: Map<string, any>) {
    return state.get('error');
}

export function getEmail(state: Map<string, any>) {
    const user = state.get('user');

    if (!user) {
        return '';
    }

    return user.get('email');
}

export function hasRole(state: Map<string, any>, ...requestedRoles: string[]) {
    const user = state.get('user');

    if (!user) {
        return false;
    }

    const roles = user.get('roles');

    if (!roles) {
        return false;
    }

    return requestedRoles.reduce(
        (result, role) => result || roles.includes(role),
        false,
    );
}

export function getRoles(state: Map<string, any>) {
    const user = state.get('user');

    if (!user) {
        return null;
    }

    const roles = user.get('roles');

    if (!roles) {
        return null;
    }

    return Array.from(roles);
}

export function isLoadingMe(state: Map<string, any>) {
    return state.get('isLoadingMe');
}

export function getMe(state: Map<string, any>) {
    return state.get('me');
}

export function getCurrentClient(state: Map<string, any>) {
    return state.getIn(['me', 'client']);
}

export function getCurrentSignUpStep(state: Map<string, any>) {
    return state.getIn(['me', 'signUpStep']);
}

export function hasAuthModuleBooted(state: Map<string, any>) {
    return state.get('booted');
}
