import fetch from 'isomorphic-unfetch';
import { stringify } from 'qs';

export { parseDate } from '@bondvet/web-app-lib/util';
export { client } from '@bondvet/web-app-lib';
export { loadWithRedux } from '../util/loadWithRedux';

const API_URL = (process.env.APP_API_URL || '').replace(/\/$/, '');

interface CallOptions {
    method?: string;
    body?: any;
    headers?: { [key: string]: any };
    query?: any;
}

export async function call(
    path: string,
    {
        method = 'GET',
        body = null,
        headers = {},
        query = null,
    }: CallOptions = {},
) {
    const options: { [key: string]: any } = { method, headers };

    if (body) {
        options.body = JSON.stringify(body);
        options.headers['Content-Type'] = 'application/json';
    }

    let url = `${API_URL}/api${path}`;

    if (query) {
        url += `?${stringify(query)}`;
    }

    const response = await fetch(url, options);

    const data = response.json();

    if (response.ok) {
        return data;
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw data;
}
