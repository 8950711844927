import { withLayout } from 'core/layouts';
import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';

const Home = Loadable({
    componentName: 'Home',
    loader: () => import(/* webpackPrefetch: true */ './Home'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    reducerName: 'home',
    loading: Loading,
});

export default (prefix: string) => [
    { exact: true, path: `${prefix}`, component: withLayout(Home) },
];
