import * as React from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any */
export default function DatePickerUtilsProvider(props: any) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            utils={MomentUtils}
            moment={moment}
            {...props}
        />
    );
}
