import * as React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';

export function StyleProvider({
    children,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...props
}: React.PropsWithChildren<any>) {
    return <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>;
}
