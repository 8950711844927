import { gql } from '@apollo/client';

export interface MeFieldsType {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    addressExtra: string;
    city: string;
    zipCode: string;
    state: string;
    signUpStep: string;
    country: string;
    locations: ReadonlyArray<{ _id: string; name: string }>;
    regions: ReadonlyArray<{ _id: string; name: string }>;
    defaultLocation: {
        _id: string;
        name: string;
    };
    avatar: {
        url: string;
    };
    notifications: {
        unreadMessages: number;
    };
    animals: {
        _id: string;
        name: string;
    };
}

export const MeFields = gql`
    fragment MeFields on User {
        _id
        email
        firstName
        lastName
        phoneNumber
        address
        addressExtra
        city
        zipCode
        state
        signUpStep
        country
        locations {
            _id
            name
        }
        regions {
            _id
            name
        }
        defaultLocation {
            _id
            name
        }
        avatar {
            url
        }
        notifications {
            unreadMessages
        }
        animals {
            _id
            name
        }
    }
`;
