import './index.scss';
import './bootstrap';

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { client } from '@bondvet/web-app-lib';
import { preloadReady } from '_embedded_packages/web-app-common/util/loadableComponent';
import App from './App';

const container = document.getElementById('root');

const render = (Component: React.ComponentType) => {
    if (container) {
        const root = createRoot(container);

        root.render(
            <ApolloProvider client={client}>
                <Component />
            </ApolloProvider>,
        );
    }
};

preloadReady().then(() => {
    render(App);
});
