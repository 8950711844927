import { TranslatePlaceholderData } from '@bondvet/web-app-i18n/util/index';
import * as constants from './constants';

export function showNotification(
    message: string | null | undefined,
    data: TranslatePlaceholderData = {},
) {
    return {
        type: constants.SHOW_NOTIFICATION,
        message,
        data,
    };
}

export function clearNotifications() {
    return {
        type: constants.CLEAR_NOTIFICATIONS,
    };
}
