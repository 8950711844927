import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { compose } from 'redux';
import { withStyles } from 'tss-react/mui';
import { withTranslate, WithTranslate } from '@bondvet/web-app-i18n/util';
import { withRouter } from '@bondvet/web-app-lib/util';
import { RouteComponentProps } from 'react-router-dom';
import { getStyles, Styles } from './styles';
import logo from '../../assets/logoWithText.inline.svg';

interface HeaderProps {
    onToggleDrawer: () => void;
    mobileOpen: boolean;
    hasRightDrawer?: boolean;
    rightDrawerOpen?: boolean;
    toggleRightDrawer?: () => void;
    rightTabs: React.ReactNode;
}

function HeaderComponent({
    classes,
    onToggleDrawer,
    translate,
    hasRightDrawer,
    toggleRightDrawer,
    rightDrawerOpen,
    children,
    mobileOpen,
    history,
    rightTabs,
}: React.PropsWithChildren<HeaderProps & Styles & WithTranslate> &
    RouteComponentProps) {
    return (
        <AppBar className={classes.root} elevation={0}>
            <Toolbar disableGutters variant="dense" className={classes.toolbar}>
                <IconButton
                    color="inherit"
                    onClick={onToggleDrawer}
                    className={classes.navIconHide}
                    aria-label={translate('app.openMenu') as string}
                    size="large"
                >
                    <MenuIcon />
                </IconButton>
                {!mobileOpen && (
                    <img
                        className={classes.logo}
                        alt="logo"
                        src={logo}
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                )}
                {children}
                {!mobileOpen && rightTabs}
                {hasRightDrawer && (
                    <IconButton
                        color="inherit"
                        onClick={toggleRightDrawer}
                        className={classes.rightToggle}
                        aria-label={translate('app.openMenu') as string}
                        size="large"
                    >
                        {rightDrawerOpen ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
}

export const Header = compose<React.FC<React.PropsWithChildren<HeaderProps>>>(
    withRouter,
    withTranslate,
)(withStyles(HeaderComponent, getStyles, { name: 'header' }));
