import { Map } from 'immutable';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import * as commonSelectors from '../reducers';

export const selectors: any = omit(commonSelectors, 'initialState', 'default');

export function injectSelectors(
    reducerName: string,
    newSelectors: any,
    initialState: Map<string, any> = Map(),
) {
    forEach(newSelectors, (selector: any, name: string) => {
        selectors[name] = (state: Map<string, any>, ...args: any) =>
            selector(state.get(reducerName, initialState), ...args);
    });
}
