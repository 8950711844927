import * as React from 'react';
import Hidden from '@mui/material/Hidden';
import { compose } from 'redux';
import { WithTranslate, withTranslate } from '@bondvet/web-app-i18n/util';
import { withStyles } from 'tss-react/mui';
import { getStyles, Styles } from './styles';
import { Mobile } from './Mobile';
import { Desktop } from './Desktop';
import { NavigationTab } from '../../lib';

interface NavigationProps {
    tabs: NavigationTab[];
    rightTabs?: NavigationTab[];
    variant: 'dark' | 'light';
    homePath?: string;
    homeLabel?: string;
    mobileOpen: boolean;
    onToggleDrawer: () => void;
    noDashboard?: boolean;
}

function NavigationComponent({
    mobileOpen,
    onToggleDrawer,
    variant,
    ...props
}: NavigationProps & WithTranslate & Styles) {
    return (
        <>
            <Hidden mdDown implementation="css">
                <Desktop variant={variant} {...props} />
            </Hidden>
            <Hidden mdUp implementation="css">
                <Mobile
                    mobileOpen={mobileOpen}
                    onToggleDrawer={onToggleDrawer}
                    {...props}
                />
            </Hidden>
        </>
    );
}

export const Navigation = compose<React.FC<NavigationProps>>(withTranslate)(
    withStyles(NavigationComponent, getStyles, { name: 'navigation' }),
);
