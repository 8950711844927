import * as React from 'react';
import { GlobalStyles as TssReactGlobalStyles } from 'tss-react';
import { useStyles } from 'tss-react/mui';

import { getGlobalStyles } from './styles';

export function GlobalStyles({ children }: React.PropsWithChildren<unknown>) {
    const { theme } = useStyles();

    return (
        <>
            <TssReactGlobalStyles styles={getGlobalStyles(theme)} />
            {children}
        </>
    );
}
