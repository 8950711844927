import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';

function loadRedux() {
    return import(/* webpackChunkName: "web-app-account.redux" */ '../redux');
}

function loadTranslations() {
    return import(/* webpackChunkName: "web-app-account.i18n" */ '../i18n');
}

export function loadReduxAndTranslations() {
    return [loadRedux(), loadTranslations()];
}

export function createLoadableComponent(componentName: string, load: any) {
    return Loadable({
        componentName,
        loader: load,
        redux: loadRedux,
        translations: loadTranslations,
        reducerName: 'web-app-account',
        translationsScope: 'account',
        loading: Loading,
    });
}
