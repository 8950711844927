import bondColors from '@bondvet/web-app-lib/colors';
import { CommonColors, Color } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';
import { Theme as ThemeType } from '@mui/material/styles';

function createColors<Type extends Record<keyof Type, string>>(
    key: string,
    colors: Type,
): Record<string, string> {
    return Object.keys(colors).reduce<Record<string, string>>(
        (aggregator, color) => ({
            ...aggregator,
            [`--${key}-${color}-color`]: colors[color as keyof Type],
        }),
        {},
    );
}

function getRgbVariable(color: string): string {
    if (color.startsWith('#')) {
        let hexString = color.substring(1);

        if (hexString.length === 3) {
            hexString = [
                hexString[0],
                hexString[0],
                hexString[1],
                hexString[1],
                hexString[2],
                hexString[2],
            ].join('');
        }

        const match = hexString.match(
            /^([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})$/i,
        );

        if (match) {
            const [, redHex, greenHex, blueHex] = match;
            const red = parseInt(redHex, 16);
            const green = parseInt(greenHex, 16);
            const blue = parseInt(blueHex, 16);

            return [red, green, blue].join(', ');
        }
    } else {
        const match = color.match(/^rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)/);

        if (match) {
            const [, red, green, blue] = match;

            return [red, green, blue].join(', ');
        }
    }

    throw new Error(`invalid color: ${color}`);
}

export function getGlobalStyles(theme: ThemeType) {
    return {
        ':root': {
            '--drawer-width': '181px',
            ...createColors<PaletteColor>('primary', theme.palette.primary),
            ...createColors<PaletteColor>('secondary', theme.palette.secondary),
            ...createColors<PaletteColor>('error', theme.palette.error),
            ...createColors<CommonColors>('common', theme.palette.common),
            ...createColors<Color>('grey', theme.palette.grey),
            ...createColors('text', theme.palette.text),
            '--font-weight-light': theme.typography.fontWeightLight,
            '--font-weight-medium': theme.typography.fontWeightMedium,
            '--font-weight-regular': theme.typography.fontWeightRegular,
            '--font-weight-bold': theme.typography.fontWeightBold,
            '--primary-main-color-rgb': getRgbVariable(
                theme.palette.primary.main,
            ),
            '--primary-dark-color-rgb': getRgbVariable(
                theme.palette.primary.dark,
            ),
            '--secondary-main-color-rgb': getRgbVariable(
                theme.palette.secondary.main,
            ),
            '--error-main-color-rgb': getRgbVariable(theme.palette.error.main),
            '--divider-color': theme.palette.divider,
            '--divider-color-rgb': getRgbVariable(theme.palette.divider),
            '--success-color': bondColors.success,
            '--white-color': bondColors.white,

            '--border-radius': theme.spacing(0.5),
            '--border-radius-md': 'calc(var(--border-radius) * 2)',
            '--border-radius-lg': '10px',
        },
    };
}
