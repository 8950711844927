import omit from 'lodash/omit';

export function loadWithRedux(
    componentName: string,
    ...promises: Promise<any>[]
) {
    return Promise.all(promises).then(
        ([
            componentModule,
            { reducers, sagas, selectors, initialState },
            translations,
        ]) => {
            return {
                [componentName]: componentModule[componentName],
                reducers,
                sagas,
                selectors: omit(
                    selectors,
                    'default',
                    'initialState',
                    '__esModule',
                ),
                translations: translations && omit(translations, '__esModule'),
                initialState,
            };
        },
    );
}
