import type {
    QueryResult,
    QueryHookOptions,
    DocumentNode,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';
import { GRAPHQL_CLIENT_NAMES } from 'common/lib/constants';
import useQueryWithClientName from './useQueryWithClientName';

export default function useAnalyticsQuery<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
    return useQueryWithClientName<TData, TVariables>(
        GRAPHQL_CLIENT_NAMES.analytics,
        query,
        options,
    );
}
