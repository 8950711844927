import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { withLayout } from 'core/layouts';

export const SchedulingSettings = Loadable({
    componentName: 'SchedulingSettings',
    loader: () => import(/* webpackPrefetch: true */ './SchedulingSettings'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    reducerName: 'scheduling',
    loading: Loading,
});

const Scheduling = Loadable({
    componentName: 'Scheduling',
    loader: () => import(/* webpackPrefetch: true */ './Scheduling'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    reducerName: 'scheduling',
    loading: Loading,
});

export default (prefix: string) => [
    { exact: true, path: `${prefix}`, component: withLayout(Scheduling) },
];

export const UKGScheduling = Loadable({
    componentName: 'UKGScheduling',
    loader: () => import(/* webpackPrefetch: true */ './UKGScheduling'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    reducerName: 'scheduling',
    loading: Loading,
});

export function createUKGSchedulingRoutes(prefix: string) {
    return [
        {
            exact: true,
            path: `${prefix}`,
            component: withLayout(UKGScheduling),
        },
    ];
}
