import { gql } from '@apollo/client';
import { MeFields, MeFieldsType } from './common';

export interface MeQueryResult {
    me: MeFieldsType;
}

export const me = gql`
    query me {
        me {
            ...MeFields
        }
    }
    ${MeFields}
`;
