import { Map } from 'immutable';
import { Action } from 'redux';
import * as constants from '../constants';

const initialState = Map({
    isOnline: true,
});

export default function reducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    { type }: Action,
) {
    switch (type) {
        case constants.SET_OFFLINE:
            return state.set('isOnline', false);
        case constants.SET_ONLINE:
            return state.set('isOnline', true);
        default:
            return state;
    }
}

export function isOnline(state: any) {
    return state.get('isOnline');
}

export function isOffline(state: any) {
    return !isOnline(state);
}
