import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { useSelector } from 'react-redux';
import { selectors } from '@bondvet/web-app-lib/redux';
import { RouterLocation } from 'connected-react-router';
import { AccessDenied } from '../AccessDenied';

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    redirectTo?: string;
    roles?: string[];
}

interface ReduxProps {
    isLoggingIn: boolean;
    isLoggedIn: boolean;
    isLoadingUser: boolean;
    hasRole: boolean;
    location: RouterLocation<any>;
}

function PrivateRouteComponent({
    component: Child,
    redirectTo = '/login',
    roles,
    ...props
}: PrivateRouteProps) {
    const requestedRoles = React.useMemo(() => {
        if (roles) {
            return Array.isArray(roles) ? roles : [roles];
        }

        return null;
    }, [roles]);
    const { isLoggedIn, isLoadingUser, isLoggingIn, hasRole, location } =
        useSelector<unknown, ReduxProps>((state) => ({
            isLoggedIn: selectors.isLoggedIn(state),
            isLoggingIn: selectors.isLoggingIn(state),
            isLoadingUser: selectors.isLoadingUser(state),
            location: selectors.getLocation(state),
            hasRole:
                requestedRoles && requestedRoles.length
                    ? selectors.hasRole(state, ...requestedRoles)
                    : true,
        }));

    if (isLoggingIn || isLoadingUser) {
        return <Loading />;
    }

    if (isLoggedIn && hasRole) {
        return <Child {...props} location={location} />;
    }

    if (isLoggedIn) {
        return <AccessDenied />;
    }

    return (
        <Redirect
            to={`${redirectTo}?from=${encodeURIComponent(
                `${location.pathname}${location.search}`,
            )}`}
        />
    );
}

export function PrivateRoute({ component, roles, ...rest }: any) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <PrivateRouteComponent
                    roles={roles}
                    component={component}
                    {...props}
                />
            )}
        />
    );
}
