import * as React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { StyleProvider } from './StyleProvider';
import theme from './muiTheme';

export function Theme({ children }: React.PropsWithChildren<any>) {
    return (
        <StyleProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </StyleProvider>
    );
}
