import * as React from 'react';
import type { Region } from '@bondvet/types/analytics';
import { regionsQuery } from 'admin/api/queries';
import useAnalyticsQuery from './useAnalyticsQuery';

type RegionsResult = {
    getRegions: ReadonlyArray<Region>;
};

type Regions = {
    regions: ReadonlyArray<Region>;
    isLoading: boolean;
    refetch: () => void;
};

export default function useRegions(): Regions {
    const { data, loading, refetch } = useAnalyticsQuery<RegionsResult>(
        regionsQuery,
        {
            fetchPolicy: 'network-only',
        },
    );

    return {
        regions: React.useMemo(
            () =>
                (data?.getRegions || [])
                    .slice(0)
                    .sort((a, b) => (a.name || '').localeCompare(b.name || '')),
            [data?.getRegions],
        ),
        isLoading: loading,
        refetch,
    };
}
