import { ROLES } from '@bondvet/roles';
import { Authorize } from './Authorize';

export default (prefix: string) => [
    {
        exact: true,
        path: `${prefix}/authorize`,
        component: Authorize,
        roles: [ROLES.admin],
    },
];
