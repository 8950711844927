import * as React from 'react';
import { gql } from '@apollo/client';
import { OperationResultWithId, OperationResult } from '@bondvet/types';
import { Services } from '@bondvet/types/common';
import {
    AnalyticsEventInput,
    AnalyticsEventDurationInput,
} from '@bondvet/types/analytics';
import useAnalyticsMutation from './useAnalyticsMutation';
import useMe from './useMe';

export type TrackEventResult = {
    trackEvent: OperationResultWithId;
};

export type TrackEventDurationResult = {
    trackEventDuration: OperationResult;
};

export const trackEventMutation = gql`
    mutation trackEvent($event: EventInput!) {
        trackEvent(event: $event) {
            id
            success
            error
        }
    }
`;

export const trackEventDurationMutation = gql`
    mutation trackEventDuration($input: EventDurationInput!) {
        trackEventDuration(input: $input) {
            success
            error
        }
    }
`;

export const locationsQuery = gql`
    query locations {
        locations {
            _id
            name
        }
    }
`;

type UseAnalytics = {
    trackEvent: (
        page: string,
        event: string,
        data?: unknown,
    ) => Promise<string | undefined>;
    trackEventDuration: (eventId: string) => Promise<void>;
};

type AnalyticsInput = {
    event: AnalyticsEventInput;
};

type EventDurationInput = {
    input: AnalyticsEventDurationInput;
};

export function useAnalytics(): UseAnalytics {
    const [runTrackEvent] = useAnalyticsMutation<
        TrackEventResult,
        AnalyticsInput
    >(trackEventMutation, {});
    const provider = useMe();

    const trackEvent = React.useCallback(
        async (page: string, event: string, data?: unknown) => {
            if (provider?._id) {
                const result = await runTrackEvent({
                    variables: {
                        event: {
                            userId: provider?._id,
                            component: Services.teamApp,
                            page,
                            event,
                            data_json: data ? JSON.stringify(data) : null,
                        },
                    },
                });
                if (!result.data?.trackEvent?.success) {
                    console.error(
                        'analytics.trackEvent error: ',
                        result.data?.trackEvent?.error,
                    );
                    return undefined;
                }
                return result.data?.trackEvent?.id;
            }
            return undefined;
        },
        [provider?._id, runTrackEvent],
    );
    const [runTrackEventDuration] = useAnalyticsMutation<
        TrackEventDurationResult,
        EventDurationInput
    >(trackEventDurationMutation, {});

    const trackEventDuration = React.useCallback(
        async (eventId: string) => {
            const result = await runTrackEventDuration({
                variables: {
                    input: {
                        eventId,
                    },
                },
            });
            if (!result.data?.trackEventDuration?.success) {
                console.error(
                    'analytics.trackEvent error: ',
                    result.data?.trackEventDuration?.error,
                );
            }
        },
        [runTrackEventDuration],
    );

    return {
        trackEvent,
        trackEventDuration,
    };
}
