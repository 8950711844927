import colors from '@bondvet/web-app-lib/colors';
import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';

type Classes =
    | 'root'
    | 'logo'
    | 'contentWrapper'
    | 'content'
    | 'footer'
    | 'title'
    | 'button'
    | 'error'
    | 'success'
    | 'logoutButton';

export interface Styles {
    classes: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    root: {
        background: theme.palette.primary.dark,
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingTop: theme.spacing(9),
    },
    logo: {
        height: 45,
        marginBottom: theme.spacing(5),
    },
    contentWrapper: {},
    content: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        [theme.breakpoints.up('md')]: {
            width: 420,
            padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
        },
    },
    footer: {
        marginTop: theme.spacing(2),
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 300,
        '& a': {
            textDecoration: 'none',
            color: colors.white,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& + &': {
            marginTop: theme.spacing(1),
        },
    },
    title: {},
    button: {
        height: 50,
        marginTop: theme.spacing(4),
    },
    error: {
        marginTop: theme.spacing(4),
        fontSize: 12,
        lineHeight: '14px',
    },
    success: {
        extend: 'error',
        color: colors.success,
    },
    logoutButton: {
        margin: `${theme.spacing(4)} auto 0`,
        display: 'block',
    },
});
