import * as React from 'react';
import { ROLES } from '@bondvet/roles';
import { createMainLayout } from '_embedded_packages/web-app-layout/layouts/createMainLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Main = createMainLayout(
    [
        {
            path: 'region-data',
            roles: [ROLES.regionData],
        },
        {
            path: 'clinic-data',
            roles: [ROLES.clinicData],
        },
        {
            path: 'control-center',
            children: ['company-control-center', 'clinic-control-center'],
            roles: [ROLES.admin],
        },
        { path: 'admin', roles: [ROLES.admin] },
        { path: 'scheduling', roles: [ROLES.scheduling] },
        { path: 'ukg-scheduling', roles: [ROLES.ukgScheduling] },
    ],
    [{ path: 'account', icon: <AccountCircleIcon /> }],
);

export const RightDrawerContext = React.createContext({
    drawerOpen: false,
    toggleDrawer: () => {},
});

interface LayoutWithRightDrawerState {
    drawerOpen: boolean;
    toggleDrawer: () => void;
}

export class LayoutWithRightDrawer extends React.PureComponent {
    state: LayoutWithRightDrawerState = {
        drawerOpen: false,
        toggleDrawer: () => {
            this.setState((state: LayoutWithRightDrawerState) => ({
                drawerOpen: !state.drawerOpen,
            }));
        },
    };

    toggleDrawer = () => {
        this.setState((state: LayoutWithRightDrawerState) => ({
            drawerOpen: !state.drawerOpen,
        }));
    };

    render() {
        const { drawerOpen, toggleDrawer } = this.state;

        return (
            <RightDrawerContext.Provider value={this.state}>
                <Main
                    {...this.props}
                    hasRightDrawer
                    rightDrawerOpen={drawerOpen}
                    toggleRightDrawer={toggleDrawer}
                />
            </RightDrawerContext.Provider>
        );
    }
}

export function withLayout(
    WrappedComponent: React.ComponentType<unknown>,
    Layout = Main,
) {
    return function WrapperComponent(props: any) {
        return (
            <Layout homePath="/home">
                <WrappedComponent {...props} />
            </Layout>
        );
    };
}
