import { withLayout } from 'core/layouts';
import { createLoadableComponent } from '../util/loading';

function loadGoogleOAuth() {
    return import(
        /* webpackPrefetch: true, webpackChunkName: "google.GoogleOAuth" */ './GoogleOAuth'
    );
}

const GoogleOAuth = createLoadableComponent('GoogleOAuth', loadGoogleOAuth);

export default (prefix: string) => [
    {
        exact: true,
        path: `${prefix}/oauth`,
        component: withLayout(GoogleOAuth),
    },
];
