import {
    Action,
    AnyAction,
    applyMiddleware,
    compose,
    createStore,
    Middleware,
    Reducer,
    ReducersMapObject,
    Store,
} from 'redux';

import createSagaMiddleware, { Saga } from 'redux-saga';
import { isBrowser } from 'browser-or-node';
import { routerMiddleware } from 'connected-react-router/immutable';
import { MemoryHistory } from 'history/createMemoryHistory';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Map } from 'immutable';
import { injectSelectors } from '../selectors';
import createReducer from '../reducers';
import { addTranslations, initialized, setSagas } from '../actions';
import { createDynamicSaga, rootSagas } from '../sagas';
import * as constants from '../constants';

export interface ExtendedStore<S = any, A extends Action = AnyAction>
    extends Store<S, A> {
    asyncReducers?: ReducersMapObject;
    injectAsyncReducer?: (name: string, reducer: Reducer) => void;
    injectSelectors?: (
        reducerName: string,
        newSelectors: any,
        initialState: Map<string, any>,
    ) => void;
    addTranslations?: (domain: string, translations: any) => void;
    setSagas?: (sagas: Saga[]) => void;
    initialized?: () => void;
}

const sagaMiddleware = createSagaMiddleware();

let history: History | MemoryHistory;
const isServer = !isBrowser;
export function getHistory() {
    if (!history) {
        if (isServer) {
            // eslint-disable-next-line global-require
            history = require('history').createMemoryHistory();
        } else {
            // eslint-disable-next-line global-require
            history = require('history').createBrowserHistory();
        }
    }

    return history;
}

function bindMiddleware(middleware: Middleware[]) {
    return compose(applyMiddleware(...middleware));
}

let theStore: ExtendedStore;

export function injectAsyncReducer(
    store: ExtendedStore,
    name: string,
    reducer: Reducer,
) {
    if (store.asyncReducers) {
        /* eslint-disable no-param-reassign */
        store.asyncReducers[name] = reducer;

        store.replaceReducer(createReducer(getHistory(), store.asyncReducers));
    } else {
        throw new Error('store.asyncReducers not initialized!');
    }
}

interface NodeModuleWithHot extends NodeModule {
    hot: any;
}

export default function configureStore() {
    if (isBrowser && theStore) {
        // store already configured and not SSR => just return it
        return theStore;
    }
    const middleware = [sagaMiddleware, routerMiddleware(getHistory())];

    theStore = createStore(
        createReducer(getHistory()),
        composeWithDevTools(bindMiddleware(middleware)),
    );

    let sagaTask = sagaMiddleware.run(
        createDynamicSaga(
            constants.SET_SAGAS,
            rootSagas,
            constants.SAGAS_RESTARTED,
        ),
    );

    // (explicitly) handle hot reloading
    if ((module as NodeModuleWithHot).hot) {
        (module as NodeModuleWithHot).hot.accept('../reducers', () => {
            const {
                createReducer: createNextReducer,
                // eslint-disable-next-line global-require
            } = require('../reducers/index');
            theStore.replaceReducer(
                createNextReducer(getHistory(), theStore.asyncReducers),
            );
        });

        (module as NodeModuleWithHot).hot.accept('../sagas', () => {
            const {
                createDynamicSaga: createNewDynamicSaga,
                // eslint-disable-next-line global-require
            } = require('../sagas/index');
            sagaTask.cancel();
            (sagaTask as any).done.then(() => {
                sagaTask = sagaMiddleware.run(
                    createNewDynamicSaga(
                        constants.SET_SAGAS,
                        rootSagas,
                        constants.SAGAS_RESTARTED,
                    ),
                );
            });
        });
    }

    theStore.asyncReducers = {};
    theStore.injectAsyncReducer = (name: string, reducer: Reducer) =>
        injectAsyncReducer(theStore, name, reducer);
    theStore.injectSelectors = injectSelectors;
    theStore.addTranslations = (domain: string, translations: any) =>
        theStore.dispatch(addTranslations(domain, translations));
    theStore.setSagas = (sagas: Saga[]) => {
        theStore.dispatch(setSagas(sagas));
    };
    theStore.initialized = () => {
        theStore.dispatch(initialized());
    };

    return theStore;
}
