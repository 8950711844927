import {
    all,
    call,
    cancelled,
    put,
    select,
    takeLatest,
    PutEffect,
    CallEffect,
    AllEffect,
    SelectEffect,
    CancelledEffect,
} from 'redux-saga/effects';
import {
    clearToken,
    storeToken,
} from '_embedded_packages/apollo-client/tokenStorage';

import { Map, Iterable } from 'immutable';
import { SAGAS_RESTARTED } from '@bondvet/web-app-lib/redux/common/constants';
import camelCase from 'lodash/camelCase';
import jwtDecode from 'jwt-decode';
import { push } from 'connected-react-router/immutable';
import { selectors } from '@bondvet/web-app-lib/redux';
import { Action } from 'redux';
import { Location as HistoryLocation } from 'history';
import { RouterLocation } from 'connected-react-router';
import { GraphQLError } from 'graphql';
import { MeFieldsType } from '../api/common';
import {
    CreateUserAction,
    CsrfToken,
    ForgotPasswordAction,
    ForgotPasswordValues,
    Identity,
    JwtData,
    LoginAction,
    LoginValues,
    OnUserLoadedAction,
    ResetPasswordAction,
    ResetPasswordValues,
    SetDefaultLocationAction,
} from './types';
import {
    createUser as _createUser,
    CreateUserOptions,
    forgotPassword as _forgotPassword,
    loadMe as _loadMe,
    login as _login,
    logout as _logout,
    resetPassword as _resetPassword,
    setDefaultLocation as _setDefaultLocation,
} from '../api';
import { clearIdentity, getIdentity, storeIdentity } from '../lib/storage';
import * as routines from './routines';

export function* accessTokenReceived(
    token: string,
): Generator<
    CallEffect<unknown> | PutEffect<Action<string | undefined>>,
    void,
    JwtData
> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { sub: _id, roles, scope } = yield call(jwtDecode, token);
    yield put(routines.login.success({ _id, roles, scope }));
    yield put(routines.loadMe.trigger());
}

function* identityReceived(
    identity: Identity,
): Generator<CallEffect<void> | PutEffect<Action<string | undefined>>, void> {
    yield call(storeIdentity, identity);
    yield put(routines.login.success(identity));
    yield put(routines.loadMe.trigger());
}

export function* tokenPairReceived({
    accessToken,
    refreshToken,
}: CsrfToken): Generator<CallEffect<unknown>> {
    yield call(storeToken, {
        accessToken,
        refreshToken,
    });
    yield call(accessTokenReceived, accessToken);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { sub: _id, roles, scope } = jwtDecode<JwtData>(accessToken);

    yield call(identityReceived, { _id, roles, scope });
}

export function* login({
    payload: { values },
}: LoginAction): Generator<
    PutEffect<Action<string | undefined>> | CallEffect<CsrfToken>,
    void,
    CsrfToken
> {
    const { email, password } = Iterable.isIterable(values)
        ? ((
              values as Map<LoginValues, string>
          ).toJS() as unknown as LoginValues)
        : (values as LoginValues);

    yield put(routines.login.request());

    try {
        const result = yield call(_login, {
            email,
            password,
        });
        yield call(tokenPairReceived, result);
    } catch (error: any) {
        yield put(routines.login.failure(error.error || error.message));
    } finally {
        yield put(routines.login.fulfill());
    }
}

export function* logout(): Generator<
    | PutEffect<Action<string | undefined>>
    | AllEffect<SelectEffect | CallEffect<void>>,
    void,
    [HistoryLocation]
> {
    yield put(routines.logout.request());
    const [location] = yield all([
        select(selectors.getLocation),
        call(_logout),
        call(clearToken),
        call(clearIdentity),
    ]);

    yield put(push(`/login?from=${encodeURIComponent(location.pathname)}`));
}

export function* loadMe(): Generator<
    | PutEffect<Action<string | undefined>>
    | CallEffect<MeFieldsType>
    | CancelledEffect,
    void
> {
    try {
        yield put(routines.loadMe.request());
        const me = yield call(_loadMe);
        yield put(routines.loadMe.success(me));
    } catch (error) {
        yield put(routines.loadMe.failure(error));
        yield put(routines.logout.trigger());
    } finally {
        const loadMeWasCancelled = yield cancelled();
        if (!loadMeWasCancelled) {
            yield put(routines.loadMe.fulfill());
        }
    }
}

export function* watchLogin() {
    yield takeLatest(routines.login.TRIGGER, login);
}

export function* readStoredUser(): Generator<
    PutEffect<Action<any>> | CallEffect<Identity | null | void>,
    void,
    Identity
> {
    try {
        yield put(routines.readStoredUser.request());
        const identity = yield call(getIdentity);
        if (identity) {
            yield call(identityReceived, identity);
        }
    } catch (error) {
        // no refresh token => nothing to do
    } finally {
        yield put(routines.readStoredUser.fulfill());
    }
}

export function* createUser({
    payload,
}: CreateUserAction): Generator<
    CallEffect<CsrfToken> | PutEffect<Action<string | undefined>>,
    void,
    CsrfToken
> {
    const user = Iterable.isIterable(payload.values)
        ? ((
              payload.values as Map<CreateUserOptions, string>
          ).toJS() as unknown as CreateUserOptions)
        : (payload.values as CreateUserOptions);

    try {
        const result = yield call(_createUser, user);
        yield call(tokenPairReceived, result);
        yield put(routines.createUser.success());
    } catch (error) {
        yield put(routines.createUser.failure(error));
    } finally {
        yield put(routines.createUser.fulfill());
    }
}

export function* onUserLoaded({
    payload: { signUpStep },
}: OnUserLoadedAction): Generator<
    SelectEffect | PutEffect<Action<string | unknown>>,
    void
> {
    const location = (yield select(
        selectors.getLocation,
    )) as RouterLocation<never>;

    if (!/^\/signup/.test(location.pathname) && signUpStep) {
        yield put(push(`/signup/${signUpStep}`));
    }
}

export function* forgotPassword({
    payload: { values },
}: ForgotPasswordAction): Generator<
    | CallEffect<{ success: any; error: any }>
    | PutEffect<Action<string | undefined>>,
    void,
    { success: any }
> {
    const { email } = (
        Iterable.isIterable(values)
            ? (values as Map<ForgotPasswordValues, string>).toJS()
            : values
    ) as { email: string };
    try {
        const { success } = yield call(_forgotPassword, email);
        yield put(routines.forgotPassword.success(success));
    } catch (error) {
        yield put(routines.forgotPassword.failure(error));
    } finally {
        yield put(routines.forgotPassword.fulfill());
    }
}

export function* resetPassword({
    payload: { values },
}: ResetPasswordAction): Generator<
    CallEffect<CsrfToken> | PutEffect<Action<string | unknown>>,
    void,
    CsrfToken
> {
    const { token, newPassword } = Iterable.isIterable(values)
        ? ((
              values as Map<ResetPasswordValues, string>
          ).toJS() as unknown as ResetPasswordValues)
        : (values as ResetPasswordValues);

    try {
        const result = yield call(_resetPassword, {
            token,
            newPassword,
        });

        yield call(tokenPairReceived, result);
        yield put(routines.resetPassword.success());
    } catch (error: unknown) {
        const errors = ((error as any).graphQLErrors as GraphQLError[]).reduce(
            (result, { message, path }) => {
                const key = path?.slice(1).join('.') || '_error';

                return {
                    ...result,
                    [key]: `auth.errors.resetPassword.${key}.${camelCase(
                        message,
                    )}`,
                };
            },
            {},
        );
        yield put(routines.resetPassword.failure(errors));
    } finally {
        yield put(routines.resetPassword.fulfill());
    }
}

export function* setDefaultLocation({
    payload,
}: SetDefaultLocationAction): Generator<
    | PutEffect<Action<string | unknown>>
    | CallEffect<MeFieldsType>
    | AllEffect<PutEffect<Action<string | unknown>>>,
    void,
    MeFieldsType
> {
    yield put(routines.setDefaultLocation.request());

    try {
        const me = yield call(_setDefaultLocation, payload);
        yield all([
            put(routines.loadMe.success(me)),
            put(routines.setDefaultLocation.success()),
        ]);
    } catch (error) {
        yield put(routines.setDefaultLocation.failure(error));
    } finally {
        yield put(routines.setDefaultLocation.fulfill());
    }
}

export function* onSagasRestarted(): Generator<
    SelectEffect | PutEffect<Action<string | unknown>>,
    void
> {
    const isLoadingMe = yield select(selectors.isLoadingMe);
    if (isLoadingMe) {
        // aborted => restart
        yield put(routines.loadMe.trigger());
    }
}

export function* watchReadStoredUser() {
    yield takeLatest(routines.readStoredUser.TRIGGER, readStoredUser);
}

export function* watchLogout() {
    yield takeLatest(routines.logout.TRIGGER, logout);
}

export function* watchLoadMe() {
    yield takeLatest(routines.loadMe.TRIGGER, loadMe);
}

export function* watchCreateUser() {
    yield takeLatest(routines.createUser.TRIGGER, createUser);
}

export function* watchUserLoaded() {
    yield takeLatest(routines.loadMe.SUCCESS, onUserLoaded);
}

export function* watchForgotPassword() {
    yield takeLatest(routines.forgotPassword.TRIGGER, forgotPassword);
}

export function* watchResetPassword() {
    yield takeLatest(routines.resetPassword.TRIGGER, resetPassword);
}

export function* watchSetDefaultLocation() {
    yield takeLatest(routines.setDefaultLocation.TRIGGER, setDefaultLocation);
}

export function* boot(): Generator<
    SelectEffect | PutEffect<Action<string>>,
    void
> {
    const booted = yield select(selectors.hasAuthModuleBooted);

    if (!booted) {
        yield put(routines.readStoredUser.trigger());
    }
}

export function* watchSagasRestarted() {
    yield takeLatest(SAGAS_RESTARTED, onSagasRestarted);
}

export const sagas = [
    watchLogin,
    watchReadStoredUser,
    watchLogout,
    watchLoadMe,
    watchCreateUser,
    watchUserLoaded,
    watchForgotPassword,
    watchResetPassword,
    watchSetDefaultLocation,
    watchSagasRestarted,
    boot,
];
