import colors from '_embedded_packages/web-app-common/colors';
import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';

type Classes =
    | 'root'
    | 'toolbar'
    | 'navIconHide'
    | 'flex'
    | 'logo'
    | 'logoutButton'
    | 'logoutButtonLabel'
    | 'rightToggle';

export interface Styles {
    classes: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    root: {
        color: colors.primary,
        position: 'absolute',
        backgroundColor: colors.white,
        [theme.breakpoints.up('md')]: {
            width: '100vw',
        },
        boxShadow: '0px 4px 4px 0px #0000003b',
        zIndex: theme.zIndex.drawer + 1,
        paddingLeft: '2rem',
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    flex: {
        flex: 1,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    logo: {
        width: 118,
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: 'auto',
            height: 24,
        },
    },
    logoutButton: {
        textTransform: 'none',
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 300,
        color: '#10365a9c',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        background: 'none',
    },
    logoutButtonLabel: {
        letterSpacing: 0,
    },
    rightToggle: {
        extend: 'navIconHide',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        position: 'absolute',
        right: theme.spacing(2),
    },
});
