import {
    all,
    call,
    cancelled,
    put,
    take,
    takeEvery,
    CallEffect,
    PutEffect,
    ChannelTakeEffect,
    CancelledEffect,
    TakeEffect,
} from 'redux-saga/effects';
import { Action } from 'redux';

import {
    addTranslationForLanguage,
    MultipleLanguageTranslation,
    SingleLanguageTranslation,
} from 'react-localize-redux';
import { eventChannel, TakeableChannel } from 'redux-saga';
import { setOffline, setOnline } from '../actions';
import * as constants from '../constants';
import * as actions from '../actions';

export interface CreateNetworkStatusChannel {
    online?: boolean;
}

function createNetworkStatusChannel(): TakeableChannel<CreateNetworkStatusChannel> {
    return eventChannel((emitter) => {
        const hasWindow = typeof window !== 'undefined';

        let timer: number | null = null;
        const onOnline = () => {
            emitter({ online: true });
        };

        const onOffline = () => {
            emitter({ online: false });
        };

        timer = window.setTimeout(() => {
            emitter({ online: !hasWindow || window.navigator.onLine });
            timer = null;
        });
        if (hasWindow) {
            window.addEventListener('online', onOnline);
            window.addEventListener('offline', onOffline);
        }
        document.body.addEventListener('online', onOnline);
        document.body.addEventListener('offline', onOffline);
        document.addEventListener('online', onOnline);
        document.addEventListener('offline', onOffline);

        return () => {
            document.body.removeEventListener('online', onOnline);
            document.body.removeEventListener('offline', onOffline);
            document.removeEventListener('online', onOnline);
            document.removeEventListener('offline', onOffline);

            if (hasWindow) {
                window.removeEventListener('online', onOnline);
                window.removeEventListener('offline', onOffline);
            }

            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    });
}

function* watchNetworkStatus(): Generator<
    | CallEffect<TakeableChannel<CreateNetworkStatusChannel>>
    | PutEffect<Action<any>>
    | ChannelTakeEffect<CreateNetworkStatusChannel>
    | TakeEffect,
    void,
    | ChannelTakeEffect<CreateNetworkStatusChannel>
    | TakeableChannel<CreateNetworkStatusChannel>
> {
    if (typeof window !== 'undefined') {
        const channel = yield call(createNetworkStatusChannel);

        try {
            while (true) {
                const { online } = (yield take(
                    channel as TakeableChannel<CreateNetworkStatusChannel>,
                )) as any;

                if (online) {
                    yield put(setOnline());
                } else {
                    yield put(setOffline());
                }
            }
        } finally {
            (channel as any).close();
        }
    }
}

interface AddTranslationsAction extends Action {
    payload: {
        domain: string;
        translations: { [key: string]: SingleLanguageTranslation };
    };
}

function* addTranslations({
    payload: { translations, domain },
}: AddTranslationsAction) {
    yield all(
        Object.keys(translations).map((language) =>
            put(
                addTranslationForLanguage(
                    { [domain]: translations[language] },
                    language,
                ),
            ),
        ),
    );
}

interface LoadTranslationsAction extends Action {
    payload: {
        domain: string;
        loader: () => MultipleLanguageTranslation;
    };
}

function* loadTranslations({
    payload: { domain, loader },
}: LoadTranslationsAction): Generator<
    | PutEffect<Action<MultipleLanguageTranslation | string | unknown>>
    | CallEffect<MultipleLanguageTranslation>
    | CancelledEffect,
    void,
    MultipleLanguageTranslation
> {
    try {
        const translations = yield call(loader);
        yield put(actions.addTranslations(domain, translations));
    } finally {
        const wasCancelled = yield cancelled();

        if (wasCancelled) {
            yield put(actions.loadTranslations(domain, loader));
        }
    }
}

function* watchAddTranslations() {
    yield takeEvery(constants.ADD_TRANSLATIONS, addTranslations);
}

function* watchLoadTranslations() {
    yield takeEvery(constants.LOAD_TRANSLATIONS, loadTranslations);
}
export const sagas = [
    watchNetworkStatus,
    watchAddTranslations,
    watchLoadTranslations,
];
