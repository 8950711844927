import * as React from 'react';
import { GlobalStyles } from 'common/components/GlobalStyles';
import { Provider, ReactReduxContext } from 'react-redux';
import { store } from '_embedded_packages/web-app-common/redux/store';
import DatePickerUtilsProvider from '_embedded_packages/web-app-date-pickers/DatePickerUtilsProvider';
import { I18n } from '@bondvet/web-app-i18n/components/I18n';
import { Notifications } from '_embedded_packages/web-app-notifications/components/Notifications';
import { Theme } from '_embedded_packages/web-app-layout/components/Theme';
import moment from 'moment-timezone';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import useFirebaseContextHandler from './common/hooks/firebase';
import FirebaseContext from './common/hooks/firebase/context';

moment.locale('en');

const loadTranslations = () =>
    import(
        /* webpackPrefetch: true, webpackChunkName: "core.i18n" */ './core/i18n'
    );

const getLocalizeState = (state: any) => state.get('locale');

export default function App(): React.ReactElement {
    const firebaseContext = useFirebaseContextHandler();

    return (
        <Theme>
            <GlobalStyles>
                <BrowserRouter>
                    <Provider store={store} context={ReactReduxContext}>
                        <FirebaseContext.Provider value={firebaseContext}>
                            <I18n
                                load={loadTranslations}
                                store={store}
                                getState={getLocalizeState}
                            >
                                <DatePickerUtilsProvider>
                                    <main>
                                        <Notifications />
                                        <Routes />
                                    </main>
                                </DatePickerUtilsProvider>
                            </I18n>
                        </FirebaseContext.Provider>
                    </Provider>
                </BrowserRouter>
            </GlobalStyles>
        </Theme>
    );
}
