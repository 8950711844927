import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { withLayout, LayoutWithRightDrawer } from 'core/layouts';
import { ROLES } from '@bondvet/roles';

const Dashboard = Loadable({
    componentName: 'Dashboard',
    loader: () => import(/* webpackPrefetch: true */ './Dashboard'),
    translations: () => import(/* webpackPrefetch: true */ '../i18n'),
    translationsScope: 'region',
    loading: Loading,
});

export default (prefix: string) => [
    {
        exact: false,
        path: `${prefix}`,
        component: withLayout(Dashboard, LayoutWithRightDrawer as any),
        roles: [ROLES.regionData],
    },
];
