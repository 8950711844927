import * as React from 'react';
import Loadable from '@bondvet/react-loadable';
import { Loading } from '../components/Loading';

export const { preloadAll } = Loadable;

export const { preloadReady } = Loadable;

interface LoadableComponentProps {
    load: any;
    componentName: string;
    reducerName?: string;
    translationsScope?: any;
    loading?: React.ComponentType<any>;
}

export function loadableComponent({
    load,
    componentName,
    reducerName,
    translationsScope,
    loading = Loading,
}: LoadableComponentProps) {
    return Loadable({
        loader: load,
        loading,
        componentName,
        reducerName,
        translationsScope,
    });
}
