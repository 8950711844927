import {
    type QueryHookOptions,
    useQuery,
    type DocumentNode,
    type TypedDocumentNode,
    type OperationVariables,
} from '@apollo/client';
import { GraphqlClientName } from 'common/lib/constants';

export default function useQueryWithClientName<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
>(
    clientName: GraphqlClientName,
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>,
): ReturnType<typeof useQuery<TData, TVariables>> {
    return useQuery<TData, TVariables>(query, {
        ...options,
        context: {
            ...options?.context,
            clientName,
        },
    });
}
