import { loadWithRedux } from '_embedded_packages/web-app-common/lib';
import { loadableComponent } from '_embedded_packages/web-app-common/util';

export function loadReduxAndTranslations() {
    return [
        import(
            /* webpackPrefetch: true, webpackChunkName: "settings.redux" */ '../redux'
        ),
        import(
            /* webpackPrefetch: true, webpackChunkName: "settings.i18n" */ '../i18n'
        ),
    ];
}

export function createLoadableComponent(componentName: string, load: any) {
    return loadableComponent({
        load: () =>
            loadWithRedux(componentName, load(), ...loadReduxAndTranslations()),
        componentName,
        reducerName: 'settings',
    });
}
