import { useMutation, DocumentNode } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import {
    MutationHookOptions,
    MutationTuple,
} from '@apollo/client/react/types/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GRAPHQL_CLIENT_NAMES } from '../lib/constants';

export default function useAnalyticsMutation<
    TData = unknown,
    TVariables = OperationVariables,
>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
    return useMutation<TData, TVariables>(mutation, {
        ...options,
        context: {
            ...options?.context,
            clientName: GRAPHQL_CLIENT_NAMES.analytics,
        },
    });
}
