import { Theme, alpha } from '@mui/material/styles';
import { CSSObject } from 'tss-react';
import colors from '_embedded_packages/web-app-common/colors';
import { setToolbarHeight } from '../../lib';

const activeTab = (theme: Theme) => ({
    color: colors.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '$listDark &': {
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
    },
});

type Classes =
    | 'root'
    | 'desktopWrapper'
    | 'list'
    | 'listDark'
    | 'toolbar'
    | 'tab'
    | 'tabContent'
    | 'mobileTabContent'
    | 'tabBorderLine'
    | 'tabIcon'
    | 'negativeMargin'
    | 'selectedTab'
    | 'selectedTabMobile'
    | 'selectedMenuEntry'
    | 'drawerPaper'
    | 'mobileToolbar'
    | 'logo'
    | 'mobileList'
    | 'mobileItem'
    | 'popper';

export interface Styles {
    classes: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    desktopWrapper: {
        ...(theme.mixins.toolbar as CSSObject),
        ...setToolbarHeight(theme),
    },
    root: {
        ...(theme.mixins.toolbar as CSSObject),
        display: 'flex',
        alignItems: 'stretch',
        ...setToolbarHeight(theme),
    },
    list: {
        ...(theme.mixins.toolbar as CSSObject),
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginBottom: 0,
        ...setToolbarHeight(theme),
    },
    listDark: {
        '&$list': {
            backgroundColor: 'transparent',
        },
    },
    toolbar: theme.mixins.toolbar as CSSObject,
    tab: {
        ...(theme.mixins.toolbar as CSSObject),
        textDecoration: 'none',
        minWidth: 100,
        textAlign: 'center',
        fontWeight: theme.typography.fontWeightMedium,
        position: 'relative',
        color: colors.secondary,
        boxSizing: 'border-box',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        width: 'auto',
        whiteSpace: 'nowrap',
        ...setToolbarHeight(theme),
        '&:hover': activeTab(theme),
        '$listDark &': {
            color: alpha(theme.palette.common.white, 0.7),
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    tabContent: { padding: 0 },
    mobileTabContent: {
        textAlign: 'left',
    },
    tabBorderLine: {
        border: '1px solid transparent',
        position: 'absolute',
        bottom: 0,
        left: '20%',
        right: '20%',
    },
    tabIcon: {
        color: 'inherit',
        marginRight: '6px',
    },
    negativeMargin: {
        marginRight: '-12px',
    },
    selectedTab: {
        ...activeTab(theme),
        '& > div': {
            borderColor: 'black',
        },
    },
    selectedTabMobile: {
        ...activeTab(theme),
    },
    selectedMenuEntry: {
        backgroundColor: theme.palette.primary.main,
    },
    drawerPaper: {
        width: 240,
        backgroundColor: colors.white,
    },
    mobileToolbar: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
    logo: {
        height: 23,
    },
    mobileList: {
        color: theme.palette.common.white,
        padding: 0,
    },
    mobileItem: {
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
        },
    },
    popper: {
        zIndex: 1,
    },
});
