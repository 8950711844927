import * as React from 'react';
import omit from 'lodash/omit';
import { withStyles } from 'tss-react/mui';
import { WithTranslate, withTranslate } from '@bondvet/web-app-i18n/util';
import { compose } from 'redux';
import { getStyles, Styles } from './styles';
import Logo from './Logo';

function LayoutComponent({
    classes,
    children,
    translate,
}: React.PropsWithChildren<Styles & WithTranslate>) {
    return (
        <section className={classes.root}>
            <Logo className={classes.logo} />
            <main className={classes.contentWrapper}>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child as any, {
                        layoutClasses: omit(
                            classes,
                            'root',
                            'logo',
                            'contentWrapper',
                        ),
                        translate,
                    }),
                )}
            </main>
        </section>
    );
}

export const Layout = compose<React.FC<React.PropsWithChildren>>(withTranslate)(
    withStyles(LayoutComponent, getStyles, { name: 'layout--auth' }),
);
