import * as React from 'react';
import { Layout } from './Layout';

export function withLayout(WrappedComponent: React.ComponentType) {
    return function WrapperComponent(props: any) {
        return (
            <Layout>
                <WrappedComponent {...props} />
            </Layout>
        );
    };
}
