import { gql } from '@apollo/client';
import { MeFields, MeFieldsType } from './common';
import type { CsrfToken } from '../redux/types';

export interface PasswordInput {
    digest: string;
    algorithm: string;
}

const CsrfTokenPairFragment = gql`
    fragment CsrfTokenPairFragment on CsrfTokenPair {
        accessToken
        refreshToken
    }
`;

export interface UserInput {
    email: string;
    password: PasswordInput;
    firstName: string;
    lastName: string;
    agreeToS: boolean;
}

export interface CreateUserVariables {
    user: UserInput;
}

export interface CreateUserResult {
    createUser: CsrfToken;
}

export const createUser = gql`
    mutation createUser($user: UserInput!) {
        createUser(user: $user) {
            ...CsrfTokenPairFragment
        }
    }
    ${CsrfTokenPairFragment}
`;

export interface LoginVariables {
    email: string;
    password: PasswordInput;
}

export interface LoginResult {
    login: CsrfToken;
}

export const login = gql`
    mutation login($email: String!, $password: PasswordInput!) {
        login(application: teamApp, email: $email, password: $password) {
            ...CsrfTokenPairFragment
        }
    }
    ${CsrfTokenPairFragment}
`;

export interface LogoutResult {
    logout: {
        success: boolean;
    };
}

export const logout = gql`
    mutation logout($refreshToken: String!) {
        logout(refreshToken: $refreshToken) {
            success
            error
        }
    }
`;

/*
export interface ForgotPasswordVariables {
    email: string;
}

export interface ForgotPasswordResult {
    forgotPassword: {
        success: boolean;
    };
}

export const forgotPassword = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email) {
            success
        }
    }
`;
 */

export const sendLoginLink = gql`
    mutation sendLoginLink($email: String) {
        sendLoginLink(
            application: teamApp
            email: $email
            reason: reset_password
            returnTo: "/reset-password"
        ) {
            success
            error
        }
    }
`;

export const createPassword = gql`
    mutation createPassword($token: String!, $newPassword: PasswordInput!) {
        createPassword(
            application: teamApp
            token: $token
            newPassword: $newPassword
        ) {
            refreshToken
            accessToken
        }
    }
`;

export interface SetDefaultLocationVariables {
    locationId: string;
}

export interface SetDefaultLocationResult {
    setDefaultLocation: MeFieldsType;
}

export const setDefaultLocation = gql`
    mutation setDefaultLocation($locationId: ID!) {
        setDefaultLocation(locationId: $locationId) {
            ...MeFields
        }
    }
    ${MeFields}
`;
