type QueryDefinition = {
    name: {
        value: string;
    };
};
type Query = {
    definitions: {
        0: QueryDefinition;
    } & QueryDefinition[];
};

export function getQueryName({ definitions }: Query): string {
    return definitions[0].name.value;
}
