import Loadable from '@bondvet/react-loadable';

export function createLoadableComponent(componentName: string, load: any) {
    return Loadable({
        componentName,
        loader: load,
        translations: () =>
            import(
                /* webpackPrefetch: true, webpackChunkName: "google.i18n" */ '../i18n'
            ),
        translationsScope: 'google',
    });
}
