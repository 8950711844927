import * as React from 'react';

export default function svg(props: any) {
    return (
        <svg
            viewBox="0 0 118 22"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            {...props}
        >
            <defs>
                <polygon
                    id="path-1"
                    points="6.84931507e-05 0.0414977099 24.9882534 0.0414977099 24.9882534 21.8320611 6.84931507e-05 21.8320611"
                />
                <polygon
                    id="path-3"
                    points="0.0956295455 0.00913333333 6.96531818 0.00913333333 6.96531818 13.8858 0.0956295455 13.8858"
                />
            </defs>
            <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Navigation/Menu"
                    transform="translate(-17.000000, -14.000000)"
                >
                    <g id="Header-Bar">
                        <g
                            id="Group-19"
                            transform="translate(17.000000, 14.000000)"
                        >
                            <g
                                id="Group-3"
                                transform="translate(0.000000, 0.078236)"
                            >
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1" />
                                </mask>
                                <g id="Clip-2" />
                                <path
                                    d="M23.4538356,0.0414977099 C20.3084589,0.0414977099 17.2031507,0.668077863 14.3307192,1.86565038 C15.7180479,2.62372672 16.7677055,3.37340611 17.4906507,3.9561542 C18.907774,3.52874962 20.3716438,3.25030687 21.8569178,3.1352687 C21.6868836,5.28018473 21.1711301,7.38261221 20.3331164,9.35656641 C18.9800342,7.56768092 17.3707877,5.97780305 15.56,4.64100916 C13.5481849,3.12821527 12.4940753,2.72499389 12.4940753,2.72499389 C9.13243151,0.967848855 5.36256849,0.0414977099 1.53431507,0.0414977099 C0.687054795,0.0414977099 6.84931507e-05,0.72030687 6.84931507e-05,1.55748244 C6.84931507e-05,5.6303374 1.08568493,9.63719237 3.13928082,13.1449328 C5.19784247,16.6609023 8.16719178,19.5944595 11.7257534,21.6283679 C11.9634247,21.7640626 12.2288356,21.8320779 12.4940753,21.8320779 C12.7593151,21.8320779 13.024726,21.7640626 13.2623973,21.6283679 C16.8209589,19.5944595 19.7903082,16.6609023 21.8488699,13.1449328 C23.902637,9.63719237 24.9882534,5.6303374 24.9882534,1.55748244 C24.9882534,0.72030687 24.3012671,0.0414977099 23.4538356,0.0414977099 M12.4940753,18.5421542 C9.99270548,16.9630244 7.86445205,14.8572382 6.26599315,12.3860168 C7.5459589,10.4068565 9.16753425,8.6641542 11.0473288,7.2371771 C10.2274658,6.71639847 9.15332192,6.10325344 8.09441781,5.70271908 C6.82027397,6.79465802 5.66616438,8.0201084 4.65503425,9.35656641 C3.81702055,7.38261221 3.30143836,5.28018473 3.13123288,3.1352687 C5.30212329,3.30337557 7.43020548,3.81273435 9.42815068,4.64100916 C9.42815068,4.64100916 11.3146233,5.45601679 12.4940753,6.23239847 C14.9949315,7.81169618 17.1242123,9.9151313 18.7223288,12.3860168 C17.1238699,14.8572382 14.9956164,16.9630244 12.4940753,18.5421542"
                                    id="Fill-1"
                                    fill="#FF4D4D"
                                    mask="url(#mask-2)"
                                />
                            </g>
                            <path
                                d="M38.2028128,16.3308812 L41.5927034,16.3308812 C42.8405277,16.3308812 43.7140221,15.8095528 43.7140221,14.5461596 C43.7140221,13.2627605 42.81978,12.7212582 41.6343731,12.7212582 L38.2028128,12.7212582 L38.2028128,16.3308812 Z M38.2028128,10.0942744 L41.3015386,10.0942744 C42.3830328,10.0942744 43.0900228,9.61295773 43.0900228,8.46993567 C43.0900228,7.32691362 42.2998678,6.80541716 41.1352086,6.80541716 L38.2028128,6.80541716 L38.2028128,10.0942744 Z M41.5095384,4.07823613 C44.3170994,4.07823613 46.355253,5.42182082 46.355253,8.24936722 C46.355253,9.67314333 45.7105061,10.6357767 44.8370116,11.1971167 C46.0225929,11.6384217 47,12.8214554 47,14.7065424 C47,17.5742686 44.9201767,19.0782361 41.9670333,19.0782361 L35,19.0782361 L35,4.07823613 L41.5095384,4.07823613 Z"
                                id="Fill-4"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M50.9600037,13.5782361 C50.9600037,15.4183116 52.0000457,16.5021305 53.5,16.5021305 C54.9999543,16.5021305 56.0399963,15.4183116 56.0399963,13.5782361 C56.0399963,11.7175916 54.9999543,10.633944 53.5,10.633944 C52.0000457,10.633944 50.9600037,11.7175916 50.9600037,13.5782361 M59,13.5782361 C59,16.8905433 56.6600311,19.0782361 53.5,19.0782361 C50.3399689,19.0782361 48,16.8905433 48,13.5782361 C48,10.2455313 50.3399689,8.07823613 53.5,8.07823613 C56.6600311,8.07823613 59,10.2455313 59,13.5782361"
                                id="Fill-6"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M71,12.4824708 L71,19.0782361 L67.9520626,19.0782361 L67.9520626,13.0669256 C67.9520626,11.6892571 67.2213627,10.9170418 66.0522429,10.9170418 C64.8414688,10.9170418 64.0481124,11.793549 64.0481124,13.087749 L64.0481124,19.0782361 L61,19.0782361 L61,8.286995 L63.8809703,8.286995 L63.8809703,9.39325952 C64.3612195,8.64186758 65.321543,8.07823613 66.7829428,8.07823613 C69.2673224,8.07823613 71,9.68548693 71,12.4824708"
                                id="Fill-8"
                                fill="#FFFFFF"
                            />
                            <path
                                d="M76.0148262,13.7620636 C76.0148262,15.5802353 77.074131,16.5882322 78.5612221,16.5882322 C80.0278205,16.5882322 81.0463105,15.6395488 81.0463105,13.9794357 L81.0463105,13.5248099 C81.0463105,11.8845784 80.0278205,10.916179 78.5612221,10.916179 C77.074131,10.916179 76.0148262,11.9438918 76.0148262,13.7620636 L76.0148262,13.7620636 Z M81.209228,18.860864 L81.209228,17.8134354 C80.659339,18.5051492 79.5592194,19.0782361 78.0722991,19.0782361 C75.0981168,19.0782361 73,16.9636629 73,13.7620636 C73,10.5407483 75.0981168,8.44589099 78.0722991,8.44589099 C79.4371167,8.44589099 80.4964215,8.920067 81.0463105,9.53275146 L81.0463105,4.07823613 L84,4.07823613 L84,18.860864 L81.209228,18.860864 Z"
                                id="Fill-10"
                                fill="#FFFFFF"
                            />
                            <polygon
                                id="Fill-12"
                                fill="#FF4D4D"
                                points="91.2170868 19.0782361 85 4.07823613 88.3353924 4.07823613 92.4999173 14.2654055 96.6447521 4.07823613 100 4.07823613 93.7829132 19.0782361"
                            />
                            <path
                                d="M103.039204,12.5354858 L107.117613,12.5354858 C107.058766,11.2473674 106.431331,10.4091906 105.137339,10.4091906 C103.921591,10.4091906 103.117613,11.186004 103.039204,12.5354858 L103.039204,12.5354858 Z M103.039204,14.5186569 C103.098052,15.827344 103.862743,16.6655209 105.1569,16.6655209 C106.156818,16.6655209 106.803814,16.2361481 107.019643,15.5001294 L109.90203,15.6432537 C109.548944,17.6469934 107.607792,19.0782361 105.235309,19.0782361 C102.137257,19.0782361 100,16.9723382 100,13.5781504 C100,10.1637367 102.137257,8.07823613 105.1569,8.07823613 C108.078409,8.07823613 110,10.0410098 110,13.332868 L110,14.5186569 L103.039204,14.5186569 Z"
                                id="Fill-14"
                                fill="#FF4D4D"
                            />
                            <g
                                id="Group-18"
                                transform="translate(111.000000, 5.078236)"
                            >
                                <mask id="mask-4" fill="white">
                                    <use xlinkHref="#path-3" />
                                </mask>
                                <g id="Clip-17" />
                                <path
                                    d="M4.63115227,9.7308 C4.63115227,10.8639667 5.33337955,11.2814667 6.11133409,11.2814667 C6.50985682,11.2814667 6.75660682,11.2218 6.96533409,11.1821333 L6.96533409,13.7666333 C6.56681136,13.8263 6.18722045,13.8858 5.42819773,13.8858 C3.30274318,13.8858 1.84149318,12.8123 1.860425,10.4266333 L1.87951591,5.9138 L0.0956295455,5.9138 L0.0956295455,3.4088 L1.89844773,3.4088 L1.91737955,0.00913333333 L4.68810682,0.00913333333 L4.669175,3.4088 L6.96533409,3.4088 L6.96533409,5.9138 L4.65024318,5.9138 L4.63115227,9.7308 Z"
                                    id="Fill-16"
                                    fill="#FF4D4D"
                                    mask="url(#mask-4)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
