import { createAction } from 'redux-actions';
import { MultipleLanguageTranslation } from 'react-localize-redux';
import * as constants from '../constants';

export const setSagas = createAction(constants.SET_SAGAS);
export const setOnline = createAction(constants.SET_ONLINE);
export const setOffline = createAction(constants.SET_OFFLINE);
export const addTranslations = createAction(
    constants.ADD_TRANSLATIONS,
    (domain: string, translations: MultipleLanguageTranslation) => ({
        domain,
        translations,
    }),
);
export const loadTranslations = createAction(
    constants.LOAD_TRANSLATIONS,
    (domain: string, loader: () => void) => ({ domain, loader }),
);
export const initialized = createAction(constants.INITIALIZED);
