import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';

type Classes = 'root' | 'closeIcon';

export interface Styles {
    classes: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    root: {},
    closeIcon: {
        width: theme.spacing(3),
    },
});
