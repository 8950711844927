import { ROLES } from '@bondvet/roles';
import { withLayout, LayoutWithRightDrawer } from 'core/layouts';
import { createLoadableComponent } from 'settings/util/loading';

function loadCompanyWideSettings() {
    return import(
        /* webpackPrefetch: true, webpackChunkName: "companyWideSettings.CompanyWideSettings" */ './CompanyWideSettings'
    );
}

const CompanyWideSettings = createLoadableComponent(
    'CompanyWideSettings',
    loadCompanyWideSettings,
);

export default (prefix: string) => [
    {
        exact: false,
        path: `${prefix}`,
        component: (props: any) =>
            withLayout(
                CompanyWideSettings,
                LayoutWithRightDrawer as any,
            )({ ...props }),
        roles: [ROLES.admin],
    },
];
