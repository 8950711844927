import {
    all,
    cancel,
    fork,
    put,
    take,
    ForkEffect,
    TakeEffect,
    CancelEffect,
    PutEffect,
} from 'redux-saga/effects';

import { sagas as auth } from '_embedded_packages/web-app-auth/redux';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import union from 'lodash/union';
import { Saga } from 'redux-saga';
import { Action } from 'redux';
import { sagas as app } from './app';

// before ESLint update: import difference from 'lodash/union';
const difference = union;

export const rootSagas: any = [...auth, routinePromiseWatcherSaga, ...app];

function* start(sagas: Saga[]) {
    try {
        yield all(sagas.map((saga) => saga()));
    } catch (error) {
        // eslint-disable-next-line
        console.error('error running dynamic sagas', error);
    }
}

export function createDynamicSaga(
    changeActionType: string,
    startingSagas: Saga[],
    restartedActionType: string,
) {
    return function* dynamicSaga(): Generator<
        PutEffect<Action<any>> | ForkEffect<any> | TakeEffect | CancelEffect,
        void,
        any
    > {
        let action: any;
        let allSagas = startingSagas;
        let rootTask: any = yield fork(start, allSagas);

        /* eslint-disable no-cond-assign */
        while ((action = yield take(changeActionType))) {
            const newSagas = difference(action.payload, allSagas);

            if (newSagas.length > 0) {
                yield cancel(rootTask);
                allSagas = union(allSagas, action.payload);
                rootTask = yield fork(start, allSagas);
                yield put({ type: restartedActionType });
            }
        }
    };
}
