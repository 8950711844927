import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import { FormSubmitHandler } from 'redux-form';

export const createUser = createRoutine('auth/CREATE_USER');
export const createUserFormHandler = bindRoutineToReduxForm(
    createUser,
) as FormSubmitHandler;

export const loadMe = createRoutine('auth/LOAD_ME');

export const login = createRoutine('auth/LOGIN');
export const loginFormHandler = bindRoutineToReduxForm(
    login,
) as FormSubmitHandler;

export const logout = createRoutine('auth/LOGOUT');

export const readStoredUser = createRoutine('auth/READ_STORED_USER');

export const forgotPassword = createRoutine('auth/FORGOT_PASSWORD');
export const forgotPasswordFormHandler = bindRoutineToReduxForm(
    forgotPassword,
) as FormSubmitHandler;

export const resetPassword = createRoutine('auth/RESET_PASSWORD');
export const resetPasswordFormHandler = bindRoutineToReduxForm(
    resetPassword,
) as FormSubmitHandler;

export const setDefaultLocation = createRoutine('auth/SET_DEFAULT_LOCATION');
