import * as React from 'react';
import List from '@mui/material/List';
import classnames from 'classnames';
import { WithTranslate } from '@bondvet/web-app-i18n/util';
import { Tab } from './Tab';
import { Styles } from './styles';
import { NavigationTab } from '../../lib';

interface DesktopProps extends Styles, WithTranslate {
    tabs: NavigationTab[];
    variant: 'light' | 'dark';
    homePath?: string;
    homeLabel?: string;
}

export function Desktop({ classes, tabs, translate, variant }: DesktopProps) {
    const listClasses = classnames(classes.list, {
        [classes.listDark]: variant === 'dark',
    });
    return (
        <div className={classes.root}>
            <List
                role="navigation"
                component="nav"
                classes={{ root: listClasses }}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={typeof tab === 'string' ? tab : tab.path}
                        tab={tab}
                        icon={typeof tab === 'string' ? undefined : tab.icon}
                        translate={translate}
                        classes={classes}
                    >
                        {typeof tab === 'string' ? undefined : tab.children}
                    </Tab>
                ))}
            </List>
        </div>
    );
}
