import * as React from 'react';
import { connect } from 'react-redux';
import isString from 'lodash/isString';
import { selectors } from '@bondvet/web-app-lib/redux';
import { compose } from 'redux';
import { NavLink, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import {
    ClickAwayListener,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { KeyboardArrowDown } from '@mui/icons-material';
import { WithTranslate } from '@bondvet/web-app-i18n/util';
import classnames from 'classnames';
import { NavigationTab } from '../../lib';
import { Styles } from './styles';

interface TabProps extends Styles, WithTranslate {
    tab: NavigationTab;
    icon?: React.ReactElement;
    mobile?: boolean;
    children?: NavigationTab[];
}

interface ReduxProps {
    path: string;
    hasRole: boolean;
}

interface CreateTabProps extends Styles, WithTranslate {
    path?: string;
    label?: string;
    icon?: React.ReactElement;
    children?: NavigationTab[];
    mobile?: boolean;
}

function CreateTab({
    path,
    label,
    translate,
    classes,
    icon,
    children,
    mobile,
}: CreateTabProps) {
    const { pathname } = useLocation();
    const content = translate(`app.nav.${label}`);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    let additionalProps;

    if (children) {
        const childIsActive = children.find((child) =>
            pathname.startsWith(
                `/${typeof child === 'string' ? child : child.path}`,
            ),
        );
        additionalProps = {
            className: classnames(classes.tab, {
                [classes.selectedTab]: childIsActive && !mobile,
                [classes.selectedTabMobile]: childIsActive && mobile,
            }),
            onClick: () => {
                if (children) {
                    setOpen(true);
                }
            },
        };
    } else {
        additionalProps = {
            className: classes.tab,
            component: NavLink as React.ComponentType,
            exact: path === '/',
            to: path || '',
            activeClassName: classnames({
                [classes.selectedTab]: !mobile,
                [classes.selectedTabMobile]: mobile,
            }),
        };
    }

    const iconElement = icon && (
        <ListItemIcon classes={{ root: classes.tabIcon }}>{icon}</ListItemIcon>
    );

    return (
        <>
            <ListItem button key={path} {...additionalProps}>
                {!mobile && iconElement}

                <ListItemText
                    disableTypography
                    classes={{
                        root: classnames(classes.tabContent, {
                            [classes.mobileTabContent]: mobile,
                        }),
                    }}
                >
                    {content}
                </ListItemText>
                {mobile && iconElement}
                {children && (
                    <ListItemIcon
                        classes={{
                            root: classnames(
                                classes.tabIcon,
                                classes.negativeMargin,
                            ),
                        }}
                    >
                        <KeyboardArrowDown />
                    </ListItemIcon>
                )}
                <div ref={anchorRef} className={classes.tabBorderLine} />
            </ListItem>
            {children && (
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    className={classes.popper}
                    nonce={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start'
                                        ? 'left top'
                                        : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <MenuList
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                    >
                                        {
                                            // TODO MUI button
                                            children.map((child) => {
                                                const childPath =
                                                    typeof child === 'string'
                                                        ? child
                                                        : child.path;
                                                return (
                                                    <MenuItem
                                                        key={childPath}
                                                        component={
                                                            NavLink as React.ComponentType
                                                        }
                                                        className={classes.tab}
                                                        {...{
                                                            to: `/${
                                                                childPath || ''
                                                            }`,
                                                        }}
                                                    >
                                                        {translate(
                                                            `app.nav.${childPath}`,
                                                        )}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </>
    );
}

export function TabComponent({
    hasRole,
    path,
    icon,
    children,
    translate,
    classes,
    mobile,
}: TabProps & ReduxProps) {
    if (hasRole) {
        return CreateTab({
            path: `/${path}`,
            label: path,
            icon,
            children,
            translate,
            classes,
            mobile,
        });
    }

    return null;
}

function mapStateToProps(state: any, { tab }: any) {
    if (isString(tab)) {
        return {
            hasRole: true,
            path: tab,
        };
    }

    const { roles, path } = tab;
    return {
        path,
        hasRole:
            roles && roles.length > 0
                ? selectors.hasRole(state, ...roles)
                : true,
    };
}

export const Tab = compose<React.FC<TabProps>>(connect(mapStateToProps))(
    TabComponent,
);
