import localforage from 'localforage';
import { Identity } from '../redux/types';

let IDENTITY: Identity | null;

const store = localforage.createInstance({ name: '@bondvet/web-app-auth' });

async function readStoredIdentity() {
    const identity = await store.getItem<Identity>('identity');

    IDENTITY = identity;

    return identity;
}

export async function getIdentity(): Promise<Identity | null> {
    if (IDENTITY) {
        return IDENTITY;
    }

    return readStoredIdentity();
}

export async function storeIdentity(identity: Identity) {
    IDENTITY = identity;

    await store.setItem('identity', identity);
}

export async function clearIdentity() {
    await store.removeItem('identity');
}
