import { client } from '@bondvet/web-app-lib';
import { GRAPHQL_CLIENT_NAMES } from 'common/lib/constants';
import { getRefreshToken } from '_embedded_packages/apollo-client/tokenStorage';
// eslint-disable-next-line import/no-cycle
import * as mutations from './mutations';
import * as queries from './queries';
import { createHash } from '../lib';
import type { UserInput } from './mutations';
import type { MeFieldsType } from './common';
import type { CsrfToken } from '../redux/types';

export interface CreateUserOptions extends Omit<UserInput, 'password'> {
    password: string;
}

export async function createUser({ password, ...payload }: CreateUserOptions) {
    const user = {
        ...payload,
        password: createHash(password),
    };

    const { data } = await client.mutate({
        mutation: mutations.createUser,
        variables: { __noAuth: true, user },
    });

    return data.createUser;
}

interface LoginOptions {
    email: string;
    password: string;
}

export async function login({
    email,
    password,
}: LoginOptions): Promise<CsrfToken> {
    // eslint-disable-next-line no-useless-catch
    try {
        const { data } = await client.mutate({
            mutation: mutations.login,
            variables: {
                email,
                password: createHash(password),
                __noAuth: true,
            },
            context: {
                clientName: GRAPHQL_CLIENT_NAMES.auth,
            },
        });

        return data.login;
    } catch (error) {
        throw error;
    }
}

export async function logout() {
    const refreshToken = await getRefreshToken();

    await client.mutate({
        mutation: mutations.logout,
        variables: { refreshToken, __noAuth: true },
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.auth,
        },
    });
}

export async function loadMe(): Promise<MeFieldsType> {
    const {
        data: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
            me: { __typename, ...me },
        },
    } = await client.query({ query: queries.me, fetchPolicy: 'no-cache' });

    return me;
}

export async function forgotPassword(email: string) {
    const {
        data: {
            sendLoginLink: { success, error },
        },
    } = await client.mutate({
        mutation: mutations.sendLoginLink,
        variables: { __noAuth: true, email, __noCookies: true },
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.auth,
        },
    });

    return { success, error };
}

interface ResetPasswordOptions {
    token: string;
    newPassword: string;
}

export async function resetPassword({
    token,
    newPassword,
}: ResetPasswordOptions): Promise<CsrfToken> {
    const {
        data: {
            createPassword: { accessToken, refreshToken },
        },
    } = await client.mutate({
        mutation: mutations.createPassword,
        variables: {
            token,
            newPassword: createHash(newPassword),
            __noAuth: true,
        },
        context: {
            clientName: GRAPHQL_CLIENT_NAMES.auth,
        },
    });

    return { accessToken, refreshToken };
}

export async function setDefaultLocation(
    locationId: string,
): Promise<MeFieldsType> {
    const {
        data: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
            setDefaultLocation: { __typename, ...me },
        },
    } = await client.mutate({
        mutation: mutations.setDefaultLocation,
        variables: { locationId },
    });

    return me;
}
