import colors from '_embedded_packages/web-app-common/colors';
import { Theme } from '@mui/material/styles';
import { CSSObject } from 'tss-react';
import { setToolbarHeight } from '../../lib';

type Classes =
    | 'root'
    | 'appBar'
    | 'navIconHide'
    | 'toolbar'
    | 'secondToolbar'
    | 'content'
    | 'contentWrapper'
    | 'logo';

export interface Styles {
    classes?: Record<Classes, string>;
}

export const getStyles = (theme: Theme): Record<Classes, CSSObject> => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        position: 'relative',
    },
    appBar: {
        position: 'absolute',
        backgroundColor: colors.dark,
        [theme.breakpoints.up('md')]: {
            width: '100vw',
        },
        zIndex: theme.zIndex.drawer + 1,
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: {
        ...(theme.mixins.toolbar as CSSObject),
        ...setToolbarHeight(theme),
    },
    secondToolbar: {
        ...(theme.mixins.toolbar as CSSObject),
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.mixins.toolbar.minHeight,
        },
    },
    content: {
        flexGrow: 1,
        width: '100%',
        margin: '0 auto',
    },
    contentWrapper: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        width: 95,
    },
});
