import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { withLayout } from '../layouts';

const Login = Loadable({
    componentName: 'Login',
    loader: () => import('./Login'),
    redux: () => import('../redux'),
    translations: () => import('../i18n'),
    reducerName: 'auth',
    loading: Loading,
});

const ForgotPassword = Loadable({
    componentName: 'ForgotPassword',
    loader: () => import('./ForgotPassword'),
    redux: () => import('../redux'),
    translations: () => import('../i18n'),
    reducerName: 'auth',
    loading: Loading,
});

const ResetPassword = Loadable({
    componentName: 'ResetPassword',
    loader: () => import('./ResetPassword'),
    redux: () => import('../redux'),
    translations: () => import('../i18n'),
    reducerName: 'auth',
    loading: Loading,
});

export default (prefix: string) => [
    {
        path: `${prefix}login`,
        exact: true,
        isPublic: true,
        component: withLayout(Login),
    },
    {
        path: `${prefix}forgot-password`,
        exact: true,
        isPublic: true,
        component: withLayout(ForgotPassword),
    },
    {
        path: `${prefix}reset-password`,
        exact: true,
        isPublic: true,
        component: withLayout(ResetPassword),
    },
];
