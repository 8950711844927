import { fromJS } from 'immutable';
import { Action } from 'redux';
import { TranslatePlaceholderData } from '@bondvet/web-app-i18n/util';
import * as constants from './constants';

export const initialState = fromJS({
    message: '',
    data: {},
});

interface ShowNotificationAction extends Action {
    message: string | null | undefined;
    data: TranslatePlaceholderData;
}

type ClearNotificationAction = Action;

export default function reducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state = initialState,
    { type, ...action }: ShowNotificationAction | ClearNotificationAction,
) {
    switch (type) {
        case constants.SHOW_NOTIFICATION: {
            const { message, data } = action as ShowNotificationAction;
            return state.set('message', message).set('data', fromJS(data));
        }
        case constants.CLEAR_NOTIFICATIONS:
            return state.set('message', '').set('data', fromJS({}));
        default:
            return state;
    }
}

export function getNotficationMessage(state: any) {
    return state.get('message');
}

export function getNotificationData(state: any) {
    return state.get('data');
}
