import Loadable from '@bondvet/react-loadable';
import { Loading } from '@bondvet/web-app-lib/Loading';
import { withLayout } from 'core/layouts';

const Admin = Loadable({
    componentName: 'Admin',
    loader: () =>
        import(
            /* webpackPrefetch: true, webpackChunkName: "admin.Admin" */ './Admin'
        ),
    redux: () =>
        import(
            /* webpackPrefetch: true, webpackChunkName: "admin.redux" */ '../redux'
        ),
    translations: () =>
        import(
            /* webpackPrefetch: true, webpackChunkName: "admin.i18n" */ '../i18n'
        ),
    reducerName: 'admin',
    loading: Loading,
});

export default (prefix: string) => [
    { exact: false, path: `${prefix}`, component: withLayout(Admin) },
];
