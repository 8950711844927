import {
    getActiveLanguage as _getActiveLanguage,
    getTranslate as _getTranslate,
    localizeReducer as locale,
} from 'react-localize-redux';
import {
    reducers as auth,
    selectors as fromAuth,
} from '_embedded_packages/web-app-auth/redux';
import {
    reducer as formReducer,
    formValueSelector,
} from 'redux-form/immutable';
import {
    selectors as fromNotifications,
    reducers as notifications,
} from '_embedded_packages/web-app-notifications/redux';

import { combineReducers } from 'redux-immutable';
import { ReducersMapObject } from 'redux';
import { Map } from 'immutable';
import app, * as fromApp from './app';

export const coreReducers = {
    auth,
    app,
    locale,
    notifications,
    form: formReducer,
};

export default function createReducer(asyncReducers: ReducersMapObject = {}) {
    return combineReducers({
        ...coreReducers,
        ...asyncReducers,
    });
}

/* ******************************* */
/*         redux-form              */
/* ******************************* */

export function getFormValue(
    state: Map<string, any>,
    form: string,
    ...args: any
) {
    return formValueSelector(form)(state, ...args);
}

/* ******************************* */
/*          fromAuth               */
/* ******************************* */

export function isLoggingIn(state: Map<string, any>) {
    return fromAuth.isLoggingIn(state.get('auth'));
}

export function isLoadingUser(state: Map<string, any>) {
    return fromAuth.isLoadingUser(state.get('auth'));
}

export function isLoggedIn(state: Map<string, any>) {
    return fromAuth.isLoggedIn(state.get('auth'));
}

export function getLoginError(state: Map<string, any>) {
    return fromAuth.getLoginError(state.get('auth'));
}

export function getEmail(state: Map<string, any>) {
    return fromAuth.getEmail(state.get('auth'));
}

export function hasRole(state: Map<string, any>, ...args: any) {
    return fromAuth.hasRole(state.get('auth'), ...args);
}

export function getRoles(state: Map<string, any>) {
    return fromAuth.getRoles(state.get('auth'));
}

/*
TODO
export function isAdmin(state: Map<string, any>, ...args: any) {
    return fromAuth.isAdmin(state.get('auth'), ...args);
}

export function isAdminOrHasRole(state: Map<string, any>, ...args: any) {
    return fromAuth.isAdminOrHasRole(state.get('auth'), ...args);
}
 */

export function isLoadingMe(state: Map<string, any>) {
    return fromAuth.isLoadingMe(state.get('auth'));
}

export function getMe(state: Map<string, any>) {
    return fromAuth.getMe(state.get('auth'));
}

export function getCurrentClient(state: Map<string, any>) {
    return fromAuth.getCurrentClient(state.get('auth'));
}

export function hasAuthModuleBooted(state: Map<string, any>) {
    return fromAuth.hasAuthModuleBooted(state.get('auth'));
}

/* ******************************* */
/*            i18n                 */
/* ******************************* */

export function getTranslate(state: Map<string, any>) {
    return _getTranslate(state.get('locale'));
}

export function getActiveLanguage(state: Map<string, any>) {
    return _getActiveLanguage(state.get('locale'));
}

/* ******************************* */
/*        fromNotifications        */
/* ******************************* */

export function getNotficationMessage(state: Map<string, any>) {
    return fromNotifications.getNotficationMessage(state.get('notifications'));
}

export function getNotificationData(state: Map<string, any>) {
    return fromNotifications.getNotificationData(state.get('notifications'));
}

/* ******************************* */
/*             fromApp             */
/* ******************************* */

export function isOnline(state: Map<string, any>) {
    return fromApp.isOnline(state.get('app'));
}

export function isOffline(state: Map<string, any>) {
    return fromApp.isOffline(state.get('app'));
}
